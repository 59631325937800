import { create } from "zustand";

type ThemeStore = {
  theme: "dark" | "light";
  setTheme: (theme: "dark" | "light") => void;
};

const useTheme = create<ThemeStore>((set) => ({
  theme: "dark",
  setTheme: (theme) => set({ theme }),
}));

export default useTheme;
