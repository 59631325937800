import { ReactNode } from "react";
import { Card, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import useUser from "../store/User";
import { hasViewAccess } from "../utils/authorizationUtils";
import { PiMoney, PiUsers } from "react-icons/pi";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineSportsMotorsports,
} from "react-icons/md";
import { BsCardChecklist, BsSuitcase } from "react-icons/bs";
import { CgPerformance } from "react-icons/cg";
import { LiaClipboardListSolid } from "react-icons/lia";
import { Sections } from "../models/interfaces";
import { FaRegUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ICON_SIZE = 22;

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  const shortcuts: {
    label: string;
    icon: ReactNode;
    path: string;
    section?: Sections;
  }[] = [
    {
      label: t("my_account"),
      icon: <FaRegUser size={26} />,
      path: "/account",
    },
    {
      label: t("accounts"),
      icon: <PiUsers size={26} />,
      path: "/accounts",
      section: "ONLY-SUPER",
    },
    {
      label: t("riders"),
      icon: <MdOutlineSportsMotorsports size={26} />,
      path: "/riders",
      section: "RI",
    },
    {
      label: t("waiting list"),
      icon: <LiaClipboardListSolid size={26} />,
      path: "/hiring-process/waiting-list",
      section: "WL",
    },
    {
      label: t("interview"),
      icon: <BsCardChecklist size={ICON_SIZE} />,
      path: "/hiring-process/interview",
      section: "IN",
    },
    {
      label: t("leaves"),
      icon: <BsSuitcase size={26} />,
      path: "/leaves",
      section: "LE",
    },
    {
      label: t("cash collection"),
      icon: <PiMoney size={ICON_SIZE} />,
      path: "/finance/cash-collection",
      section: "FI",
    },
    {
      label: t("performance"),
      icon: <CgPerformance size={26} />,
      path: "/performance",
      section: "PE",
    },
    {
      label: t("super panel"),
      icon: <MdOutlineAdminPanelSettings size={26} />,
      path: "/super-panel",
      section: "ONLY-SUPER",
    },
  ];

  const renderShortcuts = () => {
    return shortcuts
      .filter((shortcut) => {
        if (shortcut.section) {
          return hasViewAccess(shortcut?.section, user);
        }
        return true;
      })
      .map((shortcut) => (
        <Col key={shortcut.path} span={6} className="mb-4">
          <Card
            hoverable
            className="flex items-center justify-between p-2 bg-gray-800 text-white"
            onClick={() => navigate(shortcut.path)}
          >
            <div className="flex gap-3 items-center">
              {shortcut.icon}
              <span className="ml-2 text-lg">{shortcut.label}</span>
            </div>
          </Card>
        </Col>
      ));
  };

  return (
    <div className="p-0 m-0">
      <h1 className="text-2xl font-bold mb-4">{t("welcome")}</h1>
      <Row gutter={16}>{renderShortcuts()}</Row>

      {/* <div className="mt-8">
        <Card
          title="Latest Updates"
          className="dark:bg-gray-800 dark:text-white"
        >
          <p>
            Here you can put some static content or updates for your users. This
            is a placeholder for now.
          </p>
        </Card>
      </div> */}
    </div>
  );
};

export default HomePage;
