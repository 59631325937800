import { UploadFile, UploadProps } from "antd";
import { RiderStatus } from "./rider";

export type AccountType = "super" | "admin" | "captain" | "rider";

export interface RouteType {
  name?: Sections;
  title: string;
  hasSiderLink?: boolean;
  component?: React.ComponentType;
  path?: string;
  isPublic?: boolean;
  routes?: RouteType[];
  layout?: React.ComponentType;
}

export interface LayoutRoute {
  layout: React.ComponentType;
  routes: RouteType[];
}

export interface Account {
  birthDate: Date;
  cityID: number;
  gender: "male" | "female";
  authorizationId?: number;
  createdAt: string;
  createdBy: number;
  email: string;
  fullName: string;
  id: number;
  imageURL: string | null;
  jobTitle: string;
  phoneNumber: string;
  status: string;
  type: AccountType;
  profilePic?: UploadProps & UploadFile[];
}

export interface User {
  authorization: string;
  city: string;
  id: string;
  image: string;
  role: string;
  username: string;
}
export interface UserAuth {
  authorization: Authorization[];
  id: string;
  type: string;
  cityID: number;
}
export type UserSettings = {
  [key: string]: string | object | undefined;
  tour?: { [key: string]: boolean };
};

export interface AccountList extends Account {
  createdBy: number;
  status: string;
  cityName: string;
  lastUpdate: Date;
}
export interface AccountsList {
  data: AccountList[];
  total: number;
}

export interface Role {
  id: number;
  authorization: Authorization[];
  description: string;
  linkCount: number;
  name: string;
}

export interface RolesList {
  data: Role[];
  total: number;
}

export interface Log {
  id: number;
  section: string;
  detail: string;
  type: string;
  action: string;
  accountName: string;
  linkId: number | null;
  data: object | null;
  createdAt: string;
}

export interface LogsList {
  users: Log[];
  total: number;
}

export interface ErrorLog {
  id: number;
  name: string;
  message: string;
  stack: string;
  operational: boolean;
  frequency: number;
  fixed: boolean;
  occurredAt: string;
  reqType: string;
  route: string;
  payload: string;
}

export interface ErrorLogsList {
  data: ErrorLog[];
  total: number;
}

export interface Email {
  to: string[];
  cc: string[];
}

export interface LookupTableLeavePreferences {
  sa: number;
  su: number;
  mo: number;
  tu: number;
  we: number;
  th: number;
  fr: number;
}

export interface LeavePreset {
  name: string;
  group?: number;
  leaveReq: Email;
  leaveCancel: Email;
  admin: Email;
}
export interface LeaveEmailPreference {
  lookupTable: LookupTableLeavePreferences;
  presets: LeavePreset[];
}

export interface LeaveSetting {
  baghdad: LeaveEmailPreference;
  erbil: LeaveEmailPreference;
  sulaymaniyah: LeaveEmailPreference;
}
export interface LeavePreferncesRes {
  preference: {
    accountId: number;
    createdAt: string;
    id: number;
    type: string;
    setting: LeaveSetting;
  };
}

export interface Emails {
  adminToInputs: { id: string; value: string }[];
  reqLeaveToInputs: { id: string; value: string }[];
  leaveCancelToInputs: { id: string; value: string }[];
  adminCcInputs: { id: string; value: string }[];
  reqLeaveCcInputs: { id: string; value: string }[];
  leaveCancelCcInputs: { id: string; value: string }[];
}

export type DayAbbreviation = "sa" | "su" | "mo" | "tu" | "we" | "th" | "fr";

export interface EmailCardType {
  id: string;
  name: string;
  group?: number;
  emails: Emails;
  selectedCityDays: {
    city: string;
    days: string[];
  };
}

export interface CityList {
  countries: {
    alpha: string;
    name: string;
    region: string;
  };
  createdAt: string;
  id: number;
  name: string;
}

export interface CityListRes {
  data: CityList[];
  total: number;
}

export interface VehicleList {
  type: string;
  createdAt: string;
  id: number;
  name: string;
}

export interface VehicleListRes {
  data: VehicleList[];
  total: number;
}

export interface RiderEmails {
  adminToInputs: { id: string; value: string }[];
  freezeToInputs: { id: string; value: string }[];
  terminateToInputs: { id: string; value: string }[];
  changeDataToInputs: { id: string; value: string }[];
  adminCcInputs: { id: string; value: string }[];
  freezeCcInputs: { id: string; value: string }[];
  terminateCcInputs: { id: string; value: string }[];
  changeDataCcInputs: { id: string; value: string }[];
}

export interface RiderEmailCardType {
  id: string;
  name: string;
  emails: RiderEmails;
  selectedCity: {
    city: string;
    cityID: number;
  };
}

export interface RiderEmailResSetting {
  admin: Email;
  changeData: Email;
  freeze: Email;
  terminate: Email;
  name: string;
  cityID: number;
}
export interface RiderEmailPreferencesRes {
  accountId: number;
  createdAt: string;
  id: number;
  type: string;
  setting: RiderEmailResSetting[];
}

export interface Country {
  active: string;
  alpha: string;
  code: string;
  id: number;
  name: string;
  priority: number;
  region: string;
  subRegion: string;
}

export interface Countries {
  data: Country[];
  total: number;
}

export interface Language {
  active: string;
  code: string;
  englishName: string;
  id: number;
  nativeName: string;
  priority: number;
}

export interface LanguagesList {
  data: Language[];
  total: number;
}

export interface Tag {
  id: number;
  name: string;
  type: string;
  priority: number;
  note: string;
  subscribers: number;
}

export interface TagsResponse {
  tags: Tag[];
}

export interface TagRiderInfo {
  accountId: number;
  fullName: string;
  partnerID: number;
  phoneNumber: string;
  imageURL: string;
  city: string;
  status: RiderStatus;
  frequency: number;
}

export interface TagRiderInfoRes {
  riders: TagRiderInfo[];
  total: number;
}

export interface AccountName {
  id: number;
  fullName: string;
}

export interface AccountNames {
  data: AccountName[];
}

export type Sections =
  | "AC"
  | "RI"
  | "LE"
  | "WL"
  | "IN"
  | "PE"
  | "FI"
  | "ONLY-SUPER";
export type Actions = "add" | "edit" | "delete" | "extra";
export type AccessType =
  | "own-data"
  | "own-city"
  | "city-group"
  | "all-cities"
  | "access-group";

export type AccessView = [AccessType, number[] | null];
export type Access = [Actions, AccessType, number[] | null];
export type Authorization = [Sections, AccessView, Access[]];

export const actionOptions = [
  { label: "Add", value: "add" },
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
  { label: "Extra", value: "extra" },
];

export const accessTypeOptions = [
  { label: "Own Data", value: "own-data" },
  { label: "Own City", value: "own-city" },
  { label: "City Group", value: "city-group" },
  { label: "All Cities", value: "all-cities" },
  { label: "Access Group", value: "access-group" },
];

export type ListTypeRes = { id: number; name: string }[];

export type FinanceRecordStatus =
  | "pending"
  | "completed"
  | "cancelled"
  | "refunded"
  | "under-review";

export interface FinanceRecord {
  id: number;
  accountId: number;
  partnerID: number;
  fullName: string;
  phoneNumber: string;
  city: string;
  type: string;
  amount: number;
  status: FinanceRecordStatus;
  note: string;
  noteCount: number;
  createdAt: Date;
  createdBy: string;
  writerName: string;
}

export interface FinanceRecordRes {
  data: FinanceRecord[];
  total: number;
}

export interface Wallet {
  id: number;
  fullName: string;
  phoneNumber: string;
  city: string;
  status: string;
  balance: string;
  securityDeposit: number;
  securityDepositCompleted: boolean;
}

export interface WalletRes {
  data: Wallet[];
  total: number;
}

export interface WalletRecord {
  partnerID: number;
  fullName: string;
  phoneNumber: string;
  city: string;
  type: string;
  amount: string;
  status: string;
  note: string;
  createdAt: string;
  createdBy: string;
}

export interface WalletRecordsRes {
  data: WalletRecord[];
  total: number;
}

export interface Process {
  id: number;
  name: string;
  type: string;
  status: string;
  startedAt: string;
  completedAt: string;
  totalTasks: number;
  createdBy: string;
}

export interface ProcessesResponse {
  data: Process[];
  total: number;
}

export interface Sales {
  id: number;
  code: string;
  accountId: number;
  partnerID: string;
  fullName: string;
  phoneNumber: string;
  city: string;
  billingAmount: number;
  date: string;
  createdBy: number;
  writerName: string;
}

export interface SalesResponse {
  data: Sales[];
  total: number;
}

export interface CashOnDelivery {
  accountId: number;
  city: string;
  correspondMonth: string;
  diff: string;
  fullName: string;
  id: number;
  opningBalance: number;
  partnerID: string;
  phoneNumber: string;
  status: string;
}

export interface CashOnDeliveryRes {
  data: CashOnDelivery[];
  total: number;
}

export interface ProcessTask {
  id: number;
  processId: number;
  name: string;
  status: string;
  action: string;
  associatedRoute: string | null;
  associatedTableName: string;
  associatedFile: string;
  associatedEmail: string | null;
  startedAt: string;
  completedAt: string;
  executeAt: string;
}

export interface ProcessTasksResponse {
  data: ProcessTask[];
}

export interface CODRecord {
  amount: number;
  type: string;
  externalId: string | null;
  paymentService: string;
  createdAt: string;
  id: number;
}
export interface CODRecordRes {
  data: CODRecord[];
}
