import { DayAbbreviation } from "../models/interfaces";

export const cities: { value: number; label: string }[] = [
  {
    value: 1,
    label: "Erbil",
  },
  {
    value: 2,
    label: "Sulaymaniyah",
  },
  {
    value: 3,
    label: "Baghdad",
  },
];

export const RiderStatus: { value: number | string; label: string }[] = [
  { label: "Ready", value: "ready" },
  { label: "Working", value: "working" },
  { label: "On Leave", value: "on leave" },
  { label: "Freeze", value: "frozen" },
  { label: "Terminated", value: "terminated" },
  { label: "Resign", value: "resign" },
  { label: "Suspended", value: "suspended" },
];

export const vehicleTypes: { value: string; label: string }[] = [
  {
    value: "Car",
    label: "Car",
  },
  {
    value: "Bike",
    label: "Bike",
  },
  {
    value: "Scooter",
    label: "Scooter",
  },
];

export const genders: { value: string; label: string }[] = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

export const accountTypes: { value: string; label: string }[] = [
  {
    value: "rider",
    label: "Rider",
  },
  {
    value: "daptain",
    label: "Captain",
  },
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "super",
    label: "Super",
  },
];

export const leaveReasons = [
  "travel",
  "accident",
  "illness",
  "repair",
  "other",
];

export const mapDays: Record<DayAbbreviation, string> = {
  fr: "Fri",
  mo: "Mon",
  su: "Sun",
  sa: "Sat",
  tu: "Tue",
  we: "Wed",
  th: "Thu",
};

export const RiderLeaveStatus: { value: string; label: string }[] = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Sent", value: "sent" },
  { label: "Partner Approved", value: "partner approved" },
  { label: "Partner Rejected", value: "partner rejected" },
  { label: "Completed", value: "completed" },
  { label: "Abuse", value: "abuse" },
  { label: "Canceled By Rider", value: "canceled" },
  { label: "Cancellation Request", value: "Cancellation Request" },
];

export const allAccountTypes = [
  { label: "Super", value: "super" },
  { label: "Admin", value: "admin" },
  { label: "Rider", value: "rider" },
  { label: "Captain", value: "captain" },
  { label: "System", value: "system" },
];
export const accountStatusTypes = [
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Rejected", value: "rejected" },
  { label: "Draft", value: "draft" },
  { label: "Freeze", value: "frozen" },
  { label: "Archived", value: "archived" },
];

export const recordStatusTypes = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
  {
    label: "Refunded",
    value: "refunded",
  },
  {
    label: "Under Review",
    value: "under-review",
  },
];
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneNumberRegex = /^9647\d{9}$/;
export const phoneNumberRegexWithoutCode = /^0?7\d{9}$/;
export const otpRegex = /^\d{6}$/;
export const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const englishAlphabeticCharacterRegex = /^[a-zA-Z\s]+$/;
