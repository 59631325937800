import { Card } from "antd";
import useFetch from "../../../../hooks/useFetch";
import Loading from "../../../common/Loading";
import PerformanceCalculationSettingsForm from "./PerformanceCalculationSettingsForm";
import { PerformanceCalculationSettingsRes } from "../../../../models/performance";

const PerformanceCalculationSettingsPage = () => {
  const { data, isLoading, refetch } =
    useFetch<PerformanceCalculationSettingsRes>({
      url: "/admin/riders/performance/settings/calculation-preferences",
      method: "GET",
      isLoadingDefault: true,
    });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card title="Calculation Preferences">
      <PerformanceCalculationSettingsForm
        refetch={refetch}
        data={
          data
            ? {
                ...data?.calculationPreferences,
                dailyScoreWeight: parseFloat(
                  data?.calculationPreferences.dailyScoreWeight
                ),
                blockScoreWeight: parseFloat(
                  data?.calculationPreferences.blockScoreWeight
                ),
                dailyFineTuneFactor: parseFloat(
                  data?.calculationPreferences.dailyFineTuneFactor
                ),
                blockFineTuneFactor: parseFloat(
                  data?.calculationPreferences.blockFineTuneFactor
                ),
                workingHoursMax: parseFloat(
                  data?.calculationPreferences.workingHoursMax
                ),
                workingHoursMid: parseFloat(
                  data?.calculationPreferences.workingHoursMid
                ),
                workingHoursMin: parseFloat(
                  data?.calculationPreferences.workingHoursMin
                ),
                workingHoursWeight: parseFloat(
                  data?.calculationPreferences.workingHoursWeight
                ),
                acceptanceRateMax: parseFloat(
                  data?.calculationPreferences.acceptanceRateMax
                ),
                acceptanceRateMid: parseFloat(
                  data?.calculationPreferences.acceptanceRateMid
                ),
                acceptanceRateMin: parseFloat(
                  data?.calculationPreferences.acceptanceRateMin
                ),
                acceptanceRateWeight: parseFloat(
                  data?.calculationPreferences.acceptanceRateWeight
                ),
                lateShiftsMax: parseFloat(
                  data?.calculationPreferences.lateShiftsMax
                ),
                lateShiftsMid: parseFloat(
                  data?.calculationPreferences.lateShiftsMid
                ),
                lateShiftsMin: parseFloat(
                  data?.calculationPreferences.lateShiftsMin
                ),
                lateShiftsWeight: parseFloat(
                  data?.calculationPreferences.lateShiftsWeight
                ),
                absentShiftsMax: parseFloat(
                  data?.calculationPreferences.absentShiftsMax
                ),
                absentShiftsMid: parseFloat(
                  data?.calculationPreferences.absentShiftsMid
                ),
                absentShiftsMin: parseFloat(
                  data?.calculationPreferences.absentShiftsMin
                ),
                absentShiftsWeight: parseFloat(
                  data?.calculationPreferences.absentShiftsWeight
                ),
                breakHoursMax: parseFloat(
                  data?.calculationPreferences.breakHoursMax
                ),
                breakHoursMid: parseFloat(
                  data?.calculationPreferences.breakHoursMid
                ),
                breakHoursMin: parseFloat(
                  data?.calculationPreferences.breakHoursMin
                ),
                breakHoursWeight: parseFloat(
                  data?.calculationPreferences.breakHoursWeight
                ),
                workingDiffHoursMax: parseFloat(
                  data?.calculationPreferences.workingDiffHoursMax
                ),
                workingDiffHoursMid: parseFloat(
                  data?.calculationPreferences.workingDiffHoursMid
                ),
                workingDiffHoursMin: parseFloat(
                  data?.calculationPreferences.workingDiffHoursMin
                ),
                workingDiffHoursWeight: parseFloat(
                  data?.calculationPreferences.workingDiffHoursWeight
                ),
              }
            : undefined
        }
      />
    </Card>
  );
};

export default PerformanceCalculationSettingsPage;
