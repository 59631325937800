import { Button, Col, Form, FormProps, Input, Modal, Row } from "antd";
import { phoneNumberRegexWithoutCode } from "../../data/mockData";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { useEffect } from "react";

interface SendOTPModalProps {
  show: boolean;
  onHide: () => void;
  openForgetPasswordModal: (phoneNumber: string) => void;
  phoneNumber?: string;
  fromLogin?: boolean;
}

interface FieldType {
  phoneNumber: string;
}

const SendOTPModal = ({
  show,
  onHide,
  openForgetPasswordModal,
  phoneNumber,
  fromLogin = false,
}: SendOTPModalProps) => {
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const emailField = Form.useWatch("phoneNumber", form);
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const modifiedPhoneNumber =
      "964" + values?.phoneNumber?.replace(/^0?7/, "7");

    fetch({
      config: {
        withCredentials: false,
      },
      url: "/auth/forgot-password",
      method: "POST",
      payload: {
        phoneNumber: modifiedPhoneNumber,
      },
      onSuccess: () => {
        toast.success("OTP sent successfully!");
        onHide();
        openForgetPasswordModal(values.phoneNumber);
        form.resetFields();
      },
    });
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue({ phoneNumber: phoneNumber?.replace("964", "") });
    }
  }, [phoneNumber, form, show]);

  return (
    <Modal
      title="Send OTP"
      centered
      open={show}
      footer={null}
      onCancel={() => onHide()}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true },
                {
                  pattern: phoneNumberRegexWithoutCode,
                  message: "Please enter a valid phone number!",
                },
              ]}
            >
              <Input
                disabled={phoneNumber && !fromLogin ? true : false}
                className="w-full"
                addonBefore="+964"
                placeholder="7*********"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end w-full gap-3">
          <Button
            htmlType="button"
            onClick={() => {
              if (emailField || phoneNumber) {
                openForgetPasswordModal(emailField || phoneNumber || "");
                onHide();
              }
            }}
          >
            Enter OTP
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            Send OTP
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SendOTPModal;
