import { Button, Modal, TableProps } from "antd";
import useFetch from "../../../../hooks/useFetch";
import {
  LanguagesList as LList,
  Language,
} from "../../../../models/interfaces";
import Table from "../../../UI/Table";
import ActivatePriorityForm from "./ActivatePriorityForm";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";

interface LanguagesListProps {
  show: boolean;
  onHide: () => void;
}

const LanguagesList = ({ show, onHide }: LanguagesListProps) => {
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
  });

  const { data, isLoading, refetch } = useFetch<LList>({
    url: "/lists/languages",
    method: "GET",
    searchQuery: JSON.stringify({
      name: searchQuery.search,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
    }),
    disabled: !show,
  });

  const [showActivateModal, setShowActivateModal] = useState<{
    show: boolean;
    data?: { priority: number; active: string; id: number };
  }>({ show: false });

  const columns: TableProps<Language>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "English Name",
      dataIndex: "englishName",
    },
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "50px",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowActivateModal({
                  show: true,
                  data: {
                    priority: row.priority,
                    active: row.active,
                    id: row.id,
                  },
                });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      title={"Languages List"}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="900px"
      styles={{
        body: { maxHeight: "80vh", overflow: "hidden" },
      }}
    >
      <ActivatePriorityForm
        onHide={() => setShowActivateModal({ show: false })}
        show={showActivateModal.show}
        data={showActivateModal.data}
        refetch={refetch}
        route="/lists/languages/"
        title="Update Language"
      />
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data?.data || []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        onFilterValuesChange={(_, values) => {
          setSearchQuery({ search: values.searchText });
        }}
        scrollYH="calc(100vh - 300px)"
      />
    </Modal>
  );
};

export default LanguagesList;
