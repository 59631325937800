import { useState, useEffect } from "react";
import {
  Upload,
  Select,
  Button,
  Modal,
  Input,
  TableProps,
  Typography,
  Tooltip,
} from "antd";
import Papa from "papaparse";
import Table from "../UI/Table";
import { BsUpload } from "react-icons/bs";
import { filterOption } from "../../utils/antdUtils";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import DatePicker from "../UI/DatePicker";
import CSVInvalidViewer from "./CSVInvalidViewer";
import useTheme from "../../store/Theme";
import { downloadBlob } from "../../utils/CSVUtils";

interface CSVProcessorProps {
  onSubmit: (
    file: Blob,
    name: string,
    callback: (res?: { [key: string]: number[] }) => void
  ) => void;
  dbColumns: string[];
  mappingLocalStorageKey: string;
  tooltipTitle: string;
  hideDateField?: boolean;
  dateFormat?: string;
}

interface Mapping {
  dbColumn: string;
  csvColumn: string;
}

interface SavedMapping {
  map: Mapping[];
  name: string;
  default?: boolean;
}

const CSVProcessor = ({
  onSubmit,
  dbColumns,
  mappingLocalStorageKey,
  hideDateField,
  tooltipTitle,
  dateFormat = "YYYY-MM-DD",
}: CSVProcessorProps) => {
  const { theme } = useTheme();
  const [columns, setColumns] = useState<string[]>([]);
  const [mappings, setMappings] = useState<Mapping[]>([]);
  const [selectedMappingIndex, setSelectedMappingIndex] = useState<
    number | null
  >(null);
  const [savedMappings, setSavedMappings] = useState<SavedMapping[]>([]);
  const [data, setData] = useState<string[][]>([]);
  const [fileName, setFileName] = useState("");
  const [isMappingModalVisible, setIsMappingModalVisible] = useState(false);
  const [newMappingName, setNewMappingName] = useState("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<{
    data: string[][];
    show: boolean;
    errorDetails: { [key: string]: number[] };
  }>({ show: false, data: [], errorDetails: {} });

  useEffect(() => {
    const storedMappings = localStorage.getItem(mappingLocalStorageKey);
    if (storedMappings) {
      setSavedMappings(JSON.parse(storedMappings));
    }
  }, [columns, mappingLocalStorageKey]);

  const handleUpload = (file: File) => {
    setFileName(file.name);
    Papa.parse(file, {
      complete: (results) => {
        const defaultSavedMapIndex = savedMappings?.findIndex(
          (map) => map.default
        );
        setSelectedMappingIndex(
          defaultSavedMapIndex > -1 ? defaultSavedMapIndex : null
        );

        const defaultSavedMap = savedMappings[defaultSavedMapIndex]?.map;

        const csvColumns = results.data[0] as string[];
        const csvData = results.data.slice(1) as string[][];
        setColumns(csvColumns);
        setData(csvData);
        setMappings(
          dbColumns.map((dbCol: string) => {
            const columnValues = defaultSavedMap?.find(
              (sCol) => sCol.dbColumn === dbCol
            );
            const matchingCsvColumn = csvColumns.find((col) => col === dbCol);
            return (
              columnValues || {
                dbColumn: dbCol,
                csvColumn: matchingCsvColumn || "",
              }
            );
          })
        );
      },
      header: false,
    });
  };

  const handleCsvColumnChange = (dbColumn: string, csvColumn: string) => {
    setMappings((prevMappings) =>
      prevMappings.map((mapping) =>
        mapping.dbColumn === dbColumn ? { ...mapping, csvColumn } : mapping
      )
    );
  };

  const saveMapping = () => {
    setIsMappingModalVisible(true);
  };

  const handleSaveMapping = () => {
    if (!newMappingName) {
      return;
    }

    const updatedMappings: SavedMapping[] = [
      ...savedMappings,
      {
        map: mappings,
        name: newMappingName,
        // default: savedMappings?.length < 1,
      },
    ];

    setSavedMappings(updatedMappings);
    localStorage.setItem(
      mappingLocalStorageKey,
      JSON.stringify(updatedMappings)
    );
    setIsMappingModalVisible(false);
    setNewMappingName("");
  };

  const handleApplyMapping = (index: number) => {
    const filteredSelectedCSVMapping = savedMappings[index].map
      .filter((sm) => dbColumns.find((c) => c === sm.dbColumn))
      .map((sm) => {
        if (!columns.find((c) => c === sm.csvColumn)) {
          return {
            dbColumn: sm.dbColumn,
            csvColumn: "",
          };
        }
        return sm;
      });

    setMappings(filteredSelectedCSVMapping);
    setSelectedMappingIndex(index);
  };

  const handleSubmit = async (downloadCSV: boolean) => {
    const missingMappings = dbColumns.filter(
      (dbCol) =>
        !mappings.some(
          (mapping) => mapping.dbColumn === dbCol && mapping.csvColumn
        )
    );

    if (missingMappings.length > 0) {
      toast.warn(
        `Please select a CSV column for the following database columns: ${missingMappings.join(
          ", "
        )}`
      );
      return;
    }

    if (!selectedDate && !hideDateField) {
      toast.warn("Please select a date for the csv.");
      return;
    }

    const mappingDict = mappings.reduce((acc, mapping) => {
      if (mapping.csvColumn) {
        acc[mapping.dbColumn] = mapping.csvColumn;
      }
      return acc;
    }, {} as { [key: string]: string });

    const newHeaders = [...dbColumns];

    if (!hideDateField) {
      newHeaders.unshift("date");
    }

    const dateColumnIndex = newHeaders.indexOf("date");

    const filteredData = data
      .map((row) => {
        const dbColumnsCsvData = dbColumns.map((dbCol) => {
          const csvCol = mappingDict[dbCol];
          const csvColIndex = columns.indexOf(csvCol);
          return csvColIndex > -1 ? row[csvColIndex] : "";
        });

        if (hideDateField && dateColumnIndex > -1) {
          dbColumnsCsvData[dateColumnIndex] = moment(
            dbColumnsCsvData[dateColumnIndex]
          ).format(dateFormat);
        }

        if (hideDateField) {
          return dbColumnsCsvData;
        }

        return [
          selectedDate ? selectedDate.format(dateFormat) : "",
          ...dbColumnsCsvData,
        ];
      })
      .filter((row) => row[1]);

    const updatedData = [newHeaders, ...filteredData];

    const csvString = Papa.unparse(updatedData);
    const blob = new Blob([csvString], { type: "text/csv" });

    onSubmit(blob, fileName, (res) => {
      if (res) {
        setShowErrorModal({
          show: true,
          data: updatedData,
          errorDetails: res,
        });
      } else {
        onHideModal();
      }
      if (downloadCSV) {
        downloadBlob(blob, `processed ${fileName}`);
      }
    });
  };

  const filteredCsvColumns = columns.filter(
    (csvCol) => !mappings.find((mc) => mc.csvColumn === csvCol)
  );

  const onHideModal = () => {
    setColumns([]);
    setMappings([]);
    setData([]);
    setSavedMappings([]);
    setSelectedMappingIndex(0);
    setFileName("");
    setShowErrorModal({ show: false, data: [], errorDetails: {} });
  };

  const tableColumns: TableProps<Mapping>["columns"] = [
    {
      title: "Database Column",
      dataIndex: "dbColumn",
      key: "dbColumn",
    },
    {
      title: "CSV Column",
      dataIndex: "csvColumn",
      key: "csvColumn",
      render: (_, row) =>
        row.dbColumn === "date" && !hideDateField ? (
          <DatePicker
            onChange={(date) => setSelectedDate(date)}
            className="w-full"
          />
        ) : (
          <Select
            value={row.csvColumn}
            onChange={(value) => handleCsvColumnChange(row.dbColumn, value)}
            className="w-full"
            allowClear
            showSearch
            options={filteredCsvColumns.map((f) => ({ label: f, value: f }))}
            filterOption={filterOption}
          />
        ),
    },
  ];

  const tableData = [...mappings];
  if (!hideDateField) {
    tableData.unshift({ dbColumn: "date", csvColumn: "date" });
  }

  return (
    <>
      <Upload
        beforeUpload={(file) => {
          handleUpload(file);
          return false;
        }}
        showUploadList={false}
        accept=".csv"
        className="ant-upload-w-full"
      >
        <Tooltip title={tooltipTitle}>
          <Button
            block
            className="btn-height-40 items-center rounded-border-xl-cstm"
            icon={<BsUpload size={16} />}
          />
        </Tooltip>
      </Upload>
      <CSVInvalidViewer
        show={showErrorModal?.show}
        data={showErrorModal?.data}
        errorDetails={showErrorModal?.errorDetails}
        onHide={() =>
          setShowErrorModal({ show: false, errorDetails: {}, data: [] })
        }
      />
      <Modal
        title={`CSV Column Mapping ${fileName ? `- ${fileName}` : ""}`}
        open={columns.length > 0}
        onCancel={onHideModal}
        centered
        width="800px"
        maskClosable={false}
        footer={
          <>
            <div className="flex flex-row items-center justify-between mt-8">
              <div className="flex flex-row items-center gap-2">
                {columns.length > 0 && (
                  <>
                    <Select
                      onChange={handleApplyMapping}
                      placeholder="Select a saved mapping"
                      value={selectedMappingIndex}
                    >
                      {savedMappings.map((smap, index) => (
                        <Select.Option key={index} value={index}>
                          {smap.name} {smap.default ? "(Default)" : ""}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button onClick={saveMapping}>Save Mapping</Button>
                  </>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                <Button onClick={onHideModal}>Cancel</Button>
                {columns.length > 0 && (
                  <Button onClick={() => handleSubmit(true)}>
                    Download & Submit CSV
                  </Button>
                )}
                {columns.length > 0 && (
                  <Button type="primary" onClick={() => handleSubmit(false)}>
                    Submit CSV
                  </Button>
                )}
              </div>
            </div>
          </>
        }
        styles={{ body: { maxHeight: "80vh", overflowY: "auto" } }}
      >
        {columns.length > 0 ? (
          <>
            <Typography.Text
              className={`font-semibold bg-[#f0f0f0] p-1 ${
                theme === "dark" ? "text-black" : ""
              }`}
            >
              * Select a CSV column for each database column
            </Typography.Text>

            <Table
              data={tableData}
              columns={tableColumns}
              otherTableProps={{
                rowKey: "dbColumn",
              }}
            />
          </>
        ) : (
          <Typography.Text className="block p-2 bg-[#FAFAFA] text-center">
            Upload a CSV file to map columns
          </Typography.Text>
        )}
      </Modal>
      <Modal
        title="Save Mapping"
        open={isMappingModalVisible}
        onCancel={() => {
          setIsMappingModalVisible(false);
          setNewMappingName("");
        }}
        onOk={handleSaveMapping}
        width="400px"
      >
        <Input
          placeholder="Enter mapping name"
          value={newMappingName}
          onChange={(e) => setNewMappingName(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default CSVProcessor;
