import { useEffect, useState } from "react";
import { Badge, Button, Modal, Popconfirm, Table, Tooltip } from "antd";
import { TableProps } from "antd";
import moment from "moment-timezone";
import PocketBase from "pocketbase";
import { BiMessageDetail } from "react-icons/bi";
import ViewImageModal from "../../common/ViewImageModal";
import { AiOutlineCheck, AiOutlinePicture } from "react-icons/ai";
import { toast } from "react-toastify";
import ExternalLink from "../../common/ExternalLink";
import RiderCard from "../../common/RiderCard";
import { GrDocumentUser } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import { generateCSV } from "../../../utils/CSVUtils";

interface ComplaintRecord {
  category: string;
  status: string;
  collectionId: string;
  collectionName: string;
  created: string;
  explanation: string;
  id: string;
  location: string;
  partner_id: string;
  picture: string;
  updated: string;
}

interface ComplaintResponse {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  items: ComplaintRecord[];
}

const RiderFeedbacks = () => {
  const [data, setData] = useState<ComplaintResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [showViewImageModal, setShowViewImageModal] = useState({
    imgSrc: "",
    show: false,
  });

  const [showRiderInfo, setShowRiderInfo] = useState({
    show: false,
    partner_id: 0,
  });

  const pb = new PocketBase("https://saas.fullship.net");
  const fsComplainCollection = pb.collection("FS_App_Complain");

  const fetchData = async () => {
    setIsLoading(true);
    const result: ComplaintResponse = await fsComplainCollection.getList(
      pagination,
      (pagination - 1) * pageSize,
      { sort: "-created" }
    );
    setData(result);
    setIsLoading(false);
  };

  const refetch = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableProps["columns"] = [
    {
      title: "Partner ID",
      dataIndex: "partner_id",
      key: "partner_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Badge
          color={status === "pending" ? "#fbbd23" : "#3b82f6"}
          count={status}
          className="w-full text-center capitalize min-w-[70px]"
          showZero
        />
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (lc) => <ExternalLink url={lc} />,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (val) => moment(val).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: "Actions",
      render: (_, row) => {
        const url = pb.files.getUrl(row, row.picture, { thumb: "100x250" });
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            {row.status === "pending" && (
              <Popconfirm
                placement="bottomLeft"
                title="Change the status to sent"
                description="Are you sure you sent this report to partner?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  fsComplainCollection
                    .update(row.id, { status: "sent" })
                    .then(() => {
                      refetch();
                      toast.success("status changed to sent");
                    })
                    .catch(() => {
                      toast.error("Failed to change the status");
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <AiOutlineCheck size={14} />
                </Button>
              </Popconfirm>
            )}
            {row.picture && (
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {
                  setShowViewImageModal({
                    imgSrc: url,
                    show: true,
                  });
                }}
              >
                <AiOutlinePicture size={16} />
              </Button>
            )}

            <Tooltip title="View Explanation">
              <Button
                onClick={() =>
                  Modal.info({
                    title: "Explanation",
                    content: row.explanation,
                    centered: true,
                    closable: true,
                    maskClosable: true,
                  })
                }
                className="flex items-center px-3 py-2"
              >
                <BiMessageDetail size={16} />
              </Button>
            </Tooltip>
            <Tooltip title="View Rider Info">
              <Button
                onClick={() =>
                  setShowRiderInfo({ show: true, partner_id: row.partner_id })
                }
                className="flex items-center px-3 py-2"
              >
                <GrDocumentUser size={16} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState<ComplaintRecord[]>([]);

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: ComplaintRecord[]) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <div>
      <Modal
        title={`Rider Info: ${showRiderInfo.partner_id}`}
        centered
        open={showRiderInfo.show}
        footer={null}
        onCancel={() => {
          setShowRiderInfo({ show: false, partner_id: 0 });
        }}
        styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
        width="650px"
      >
        <RiderCard
          show={showRiderInfo.show}
          id={showRiderInfo.partner_id}
          id_type="partner_id"
        />
      </Modal>
      <ViewImageModal
        show={showViewImageModal.show}
        onHide={() => {
          setShowViewImageModal({ imgSrc: "", show: false });
        }}
        title="Picture"
        imgSrc={showViewImageModal.imgSrc}
        local
        imageProps={{ crossOrigin: "anonymous" }}
      />
      <div className="flex flex-row justify-end mb-4">
        <Tooltip title={"Export rider feedbacks"}>
          <Button
            onClick={() => {
              const selectedDataToGenerated = selectedRows?.length
                ? selectedRows
                : data?.items;
              generateCSV(
                selectedDataToGenerated?.map((d) => {
                  return {
                    partner_id: d.partner_id,
                    category: d.category,
                    status: d.status,
                    explanation: d.explanation,
                    location: d.location,
                    picture: d.picture,
                    created: moment(d.created).format("YYYY-MM-DD hh:mm:ss A"),
                  };
                }) || [],
                "riders-feedbacks"
              );
            }}
            block
            className="btn-height-40 items-center rounded-border-xl-cstm max-w-[90px]"
            icon={<BsDownload size={16} />}
          />
        </Tooltip>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data?.items || []}
        pagination={{
          current: pagination,
          pageSize,
          total: data?.totalItems,
          onChange: (page) => setPagination(page),
          showSizeChanger: true,
          onShowSizeChange: (_, size) => {
            setPageSize(size);
            refetch();
          },
        }}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
      />
    </div>
  );
};

export default RiderFeedbacks;
