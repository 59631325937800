import { Button, Col, Form, FormProps, Input, Modal, Row, Select } from "antd";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useAxios from "../../../../hooks/useAxios";
import { VehicleList } from "../../../../models/interfaces";
import { RiderTransportation } from "../../../../models/rider";
import { vehicleTypes } from "../../../../data/mockData";

interface VehicleTypeFormProps {
  show: boolean;
  data?: VehicleList;
  onHide: () => void;
  refetch: () => void;
}

interface FieldType {
  name: string;
  type: RiderTransportation;
}
const VehicleTypeForm = ({
  show,
  data,
  onHide,
  refetch,
}: VehicleTypeFormProps) => {
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      url: data?.id ? `/lists/vehicles/${data.id}` : "/lists/vehicles",
      method: data?.id ? "PUT" : "POST",
      payload: {
        ...values,
      },
      onSuccess: () => {
        onHide();
        form.resetFields();
        refetch();
        toast.success(
          data?.id
            ? "Vehicle Updated Successfully"
            : "Vehicle Added Successfully"
        );
      },
    });
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    } else {
      form.setFieldValue("name", data?.name);
      form.setFieldValue("type", data?.type);
    }
  }, [show, form, data]);

  return (
    <Modal
      title={data?.id ? "Update Vehicle" : "Add New Vehicle"}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      centered
      open={show}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select options={vehicleTypes} />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end w-full">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {data?.id ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default VehicleTypeForm;
