import { Avatar, Tooltip } from "antd";
import { MouseEvent } from "react";
import { GoPerson } from "react-icons/go";
import { LiaUserSlashSolid } from "react-icons/lia";

interface RiderProfilePicIconProps {
  onClick: (e?: MouseEvent) => void;
  imageURL?: string;
}

const RiderProfilePicIcon = ({
  onClick,
  imageURL,
}: RiderProfilePicIconProps) => {
  return (
    <Tooltip title={!imageURL && "Rider profile picture is not uploaded"}>
      <Avatar
        className={"cursor-pointer"}
        size={36}
        onClick={onClick}
        icon={
          imageURL ? <GoPerson size={20} /> : <LiaUserSlashSolid size={20} />
        }
      />
    </Tooltip>
  );
};

export default RiderProfilePicIcon;
