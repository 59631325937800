import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment-timezone";

type TimezoneContextType = {
  timezone: string;
  setTimezone: (timezone: string) => void;
};

const TimezoneContext = createContext<TimezoneContextType | null>(null);

export const TimezoneProvider = ({ children }: { children: ReactNode }) => {
  const [timezone, setTimezone] = useState("Asia/Baghdad");

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, [timezone]);

  return (
    <TimezoneContext.Provider value={{ timezone, setTimezone }}>
      {children}
    </TimezoneContext.Provider>
  );
};

export const useTimezone = () => useContext(TimezoneContext);
