import { Avatar, Button, Modal, UploadFile, UploadProps } from "antd";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { GoPerson } from "react-icons/go";
import useAxios from "../../hooks/useAxios";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import UploadAndViewImage from "../UI/UploadAndViewImage";

interface UploadProfileImageModalProps {
  selectedFiles?: UploadProps & UploadFile[];
  id?: number;
  refetch: () => void;
}

const UploadProfileImageModal = ({
  selectedFiles,
  id,
  refetch,
}: UploadProfileImageModalProps) => {
  const { fetch, isLoading } = useAxios({ disabledStates: false });
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState(selectedFiles);

  return (
    <>
      <Button
        shape="circle"
        type="text"
        className="relative w-24 h-24 cursor-pointer flex flex-col justify-center items-center rounded-full overflow-hidden mb-6"
        onClick={() => setShow(true)}
      >
        <Avatar
          icon={<GoPerson size={20} />}
          size={96}
          className="w-full h-full  flex flex-row justify-center items-center absolute"
          crossOrigin="use-credentials"
          style={{
            display: "flex",
          }}
        />
        <div className="w-full flex items-center justify-center mt-16 relative z-10 py-1 bg-black opacity-60 text-white">
          <span>
            <AiOutlineEdit />
          </span>
          <span>Edit</span>
        </div>
      </Button>
      <Modal
        title="Upload Profile Picture"
        centered
        open={show}
        footer={null}
        onCancel={() => {
          setShow(false);
          setFiles(selectedFiles);
        }}
        width="400px"
      >
        <UploadAndViewImage
          selectedFiles={files}
          title={"Uploaded Picture"}
          onChange={(fileList) => {
            setFiles(fileList);
          }}
        />
        <div className="flex justify-end w-full">
          <Button
            loading={isLoading}
            type="primary"
            className="bg-primary"
            onClick={async () => {
              if (id && files && files?.length > 0) {
                const profileFormData = new FormData();
                const originFileObj = files[0]?.originFileObj;
                if (originFileObj) {
                  const options = {
                    maxSizeMB: 0.2,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                  };

                  const compressedImage = await imageCompression(
                    originFileObj,
                    options
                  );

                  profileFormData.append(
                    "image",
                    compressedImage,
                    originFileObj.name
                  );
                  await fetch({
                    url: `/account/image`,
                    method: "PUT",
                    payload: profileFormData,
                    onSuccess: () => {
                      toast.success("Profile Picture Uploaded Successfully");
                      setShow(false);
                      setFiles(selectedFiles);
                      refetch();
                    },
                  });
                }
              }
            }}
          >
            Upload Picture
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UploadProfileImageModal;
