import { Route, Routes as ReactRoutes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { LayoutRoute, RouteType, UserAuth } from "../models/interfaces";
import { hasViewAccess } from "../utils/authorizationUtils";
import useUser from "../store/User";
import React, { ComponentType, Suspense } from "react";
import Loading from "../components/common/Loading";

const renderRoute = (
  Component: ComponentType,
  path: string,
  title: string,
  isAuthorized: boolean,
  isPublic?: boolean
) => {
  return (
    <Route
      key={`${title}-${path}`}
      element={
        <ProtectedRoute isAuthorized={isAuthorized} isPublic={isPublic} />
      }
    >
      <Route
        element={
          <Suspense fallback={<Loading />}>
            <Component />
          </Suspense>
        }
        path={path}
      />
    </Route>
  );
};

const renderRoutes = (
  route: RouteType,
  isAuthorized: boolean,
  user: UserAuth | null
): JSX.Element | null => {
  const {
    component: Component,
    path,
    title,
    isPublic,
    layout: SubLayout,
    routes: subRoutes,
  } = route;

  if (route.name && !hasViewAccess(route.name, user)) {
    return null;
  }

  if (!subRoutes && !SubLayout && Component && path) {
    return renderRoute(Component, path, title, isAuthorized, isPublic);
  }

  if (subRoutes) {
    if (SubLayout) {
      return (
        <Route key={`${title}-${path}`} element={<SubLayout />}>
          {Component &&
            path &&
            renderRoute(Component, path, title, isAuthorized, isPublic)}
          {subRoutes.map((route) => renderRoutes(route, isAuthorized, user))}
        </Route>
      );
    }
    return (
      <Route key={`${title}-${path}`}>
        {Component &&
          path &&
          renderRoute(Component, path, title, isAuthorized, isPublic)}
        {subRoutes.map((route) => renderRoutes(route, isAuthorized, user))}
      </Route>
    );
  }

  return null;
};

export const generateRoutes = (mainRoutes: LayoutRoute[]) => {
  const Routes = ({ isAuthorized }: { isAuthorized: boolean }) => {
    const { user } = useUser();
    return (
      <ReactRoutes>
        {mainRoutes.map(({ layout: MainLayout, routes }, index) => (
          <Route key={index} element={<MainLayout />}>
            {routes.map((route) => renderRoutes(route, isAuthorized, user))}
          </Route>
        ))}
      </ReactRoutes>
    );
  };

  return React.memo(Routes);
};
