import { Button, Modal, Popconfirm, TableProps } from "antd";
import useFetch from "../../../../hooks/useFetch";
import { VehicleListRes, VehicleList } from "../../../../models/interfaces";
import Table from "../../../UI/Table";
import moment from "moment-timezone";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import useAxios from "../../../../hooks/useAxios";
import VehicleTypeForm from "./VehicleTypeForm";

interface VehicleTypeProps {
  show: boolean;
  onHide: () => void;
}

const VehicleType = ({ show, onHide }: VehicleTypeProps) => {
  const [showVehicleForm, setShowVehicleForm] = useState<{
    show: boolean;
    data?: VehicleList;
  }>({
    show: false,
  });

  const { fetch } = useAxios({ disabledStates: true });
  const { data, isLoading, refetch } = useFetch<VehicleListRes>({
    url: "/lists/vehicles",
    method: "GET",
    disabled: !show,
  });

  const columns: TableProps<VehicleList>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      render: (c) => moment(c).format("YYYY-MM-DD"),
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowVehicleForm({
                  show: true,
                  data: row,
                });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title="Delete the vehicle"
              description="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                fetch({
                  url: `/lists/vehicles/${row.id}`,
                  method: "DELETE",
                  onSuccess: () => {
                    refetch();
                    toast.success("Vehicle deleted successfully");
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button danger className="flex items-center px-3 py-2">
                <AiOutlineDelete size={14} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      title={"Vehicles List"}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="800px"
    >
      <VehicleTypeForm
        show={showVehicleForm.show}
        data={showVehicleForm.data}
        onHide={() =>
          setShowVehicleForm({
            show: false,
          })
        }
        refetch={refetch}
      />
      <Button
        type="primary"
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-2 mt-4"
        onClick={() => setShowVehicleForm({ show: true })}
      >
        <AiOutlinePlus size={20} /> Add Vehicle
      </Button>
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data?.data || []}
        scrollYH="calc(100vh - 300px)"
      />
    </Modal>
  );
};

export default VehicleType;
