import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  InputNumber,
  FormProps,
  Tooltip,
} from "antd";
import { AiOutlineFilter } from "react-icons/ai";
import { Ref, useEffect, useState } from "react";
import { filterOption } from "../../utils/antdUtils";
import DatePicker from "../UI/DatePicker";
import moment, { Moment } from "moment-timezone";
import { LuFilter } from "react-icons/lu";
import { useTranslation } from "react-i18next";

export interface FilterConfig {
  type: "selection" | "dateRange" | "date" | "number" | "text";
  label: string;
  name: string;
  hidden?: boolean;
  selectionProps?: {
    mode?: "multiple" | "tags";
    options: {
      label: string;
      value: string | number;
      idValueSearch?: string | number;
    }[];
  };
}

export type DynamicDefaultFilter = {
  [key: string]: string | number | Moment | undefined | null;
};

export type FilterFormatType = {
  [key: string]: "start" | "end";
};

interface DynamicFilterModalProps<T> {
  filters: FilterConfig[];
  onApply: (values: T) => void;
  resetButtonRef?: Ref<HTMLButtonElement | HTMLAnchorElement>;
  defaultFilter?: DynamicDefaultFilter;
  filterFormats?: FilterFormatType;
}

const DynamicFilterModal = <T,>({
  filters,
  onApply,
  resetButtonRef,
  defaultFilter,
  filterFormats,
}: DynamicFilterModalProps<T>) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);

  const setDefaultFilters = () => {
    const defatltFiltersCopy = structuredClone(defaultFilter);
    if (defatltFiltersCopy) {
      Object.keys(defatltFiltersCopy).map((key) => {
        if (moment(defatltFiltersCopy[key], moment.ISO_8601, true).isValid()) {
          defatltFiltersCopy[key] = moment(defatltFiltersCopy[key]);
        }
      });
      form.setFieldsValue(defatltFiltersCopy);
    }
  };

  useEffect(() => {
    setDefaultFilters();
  }, []);

  const renderFilter = (filter: FilterConfig) => {
    if (filter.hidden) {
      return null;
    }
    switch (filter.type) {
      case "selection":
        return (
          <Form.Item label={filter.label} name={filter.name} key={filter.name}>
            <Select
              {...filter.selectionProps}
              allowClear
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
        );
      case "dateRange":
        return (
          <Form.Item label={filter.label} name={filter.name} key={filter.name}>
            <DatePicker.RangePicker className="w-full" allowEmpty />
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item label={filter.label} name={filter.name} key={filter.name}>
            <DatePicker className="w-full" />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item label={filter.label} name={filter.name} key={filter.name}>
            <InputNumber className="w-full" />
          </Form.Item>
        );
      case "text":
        return (
          <Form.Item label={filter.label} name={filter.name} key={filter.name}>
            <Input />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const onFinish: FormProps["onFinish"] = (values) => {
    Object.keys(values).map((key) => {
      if (
        Array.isArray(values[key]) &&
        values[key].length > 0 &&
        (moment.isMoment(values[key][0]) || moment.isMoment(values[key][1]))
      ) {
        if (moment.isMoment(values[key][0])) {
          values[key + "start"] = values[key][0].startOf("day");
        }

        if (moment.isMoment(values[key][1])) {
          values[key + "end"] = values[key][1].endOf("day");
        }
        delete values[key];
      }

      if (moment.isMoment(values[key])) {
        if (filterFormats && filterFormats[key]) {
          if (filterFormats[key] === "start") {
            values[key] = values[key].startOf("day");
          }
          if (filterFormats[key] === "end") {
            values[key] = values[key].endOf("day");
          }
        }
      }
    });

    onApply(values);
    setShow(false);
  };

  return (
    <>
      <Tooltip title={t("filter data")}>
        <Button
          block
          className="btn-height-40 items-center rounded-border-xl-cstm"
          icon={<LuFilter size={16} />}
          onClick={() => {
            setShow(true);
          }}
        />
      </Tooltip>
      <Modal
        open={show}
        title="Dynamic Filter"
        onCancel={() => {
          setShow(false);
        }}
        footer={
          <div className="flex justify-end w-full">
            <Button
              className="hidden me-[8px]"
              ref={resetButtonRef}
              onClick={() => {
                form.resetFields();
                setDefaultFilters();
              }}
            >
              Reset
            </Button>
            <Button className="me-[8px]" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
              icon={<AiOutlineFilter />}
            >
              Apply
            </Button>
          </div>
        }
        centered
        width="450px"
        styles={{
          body: { maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="px-1"
        >
          <Row gutter={16}>
            {filters.map((filter) => (
              <Col span={24} key={filter.name}>
                {renderFilter(filter)}
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DynamicFilterModal;
