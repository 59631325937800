import { Tooltip } from "antd";

interface TruncateTextProps {
  text: string;
  maxChars: number;
}

const TruncateText = ({ text, maxChars }: TruncateTextProps) => {
  const truncatedText =
    text.length > maxChars ? `${text.slice(0, maxChars)}...` : text;

  return (
    <Tooltip title={text}>
      <span className="text-nowrap">{truncatedText}</span>
    </Tooltip>
  );
};

export default TruncateText;
