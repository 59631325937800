import { create } from "zustand";
import {
  ListTypeRes,
  AccountNames,
  AccountName,
  AccessType,
} from "../models/interfaces";
import { get } from "../services";
import { AxiosResponse } from "axios";
import { RiderAccountName } from "../models/rider";

const getData = async <T>(url: string) => {
  try {
    const res: AxiosResponse<T> = await get(url, {
      withCredentials: true,
    });
    return { isError: false, data: res.data };
  } catch {
    return { isError: true };
  }
};

type ListsStore = {
  getLists: (listTypes: ListsReqConfig[]) => Promise<void>;

  cities: ListTypeRes;
  setCities: (val: ListTypeRes) => void;

  recordTypes: ListTypeRes;
  setRecordTypes: (val: ListTypeRes) => void;

  accounts: AccountName[];
  setAccounts: (val: AccountName[]) => void;

  countries: ListTypeRes;
  setCountries: (val: ListTypeRes) => void;

  languages: ListTypeRes;
  setLanguages: (val: ListTypeRes) => void;

  vehicles: ListTypeRes;
  setVehicles: (val: ListTypeRes) => void;

  riders: RiderAccountName[];
  setRiders: (val: RiderAccountName[]) => void;
};

type ListTypes =
  | "cities"
  | "recordTypes"
  | "accounts"
  | "countries"
  | "languages"
  | "vehicles"
  | "riders";

export interface ListsReqConfig {
  type: ListTypes;
  force?: boolean;
  disabled?: boolean | AccessType;
}

const reqLists = {
  cities: {
    url: "/lists/cities/names",
    parse: (res: ListTypeRes) => res,
  },
  recordTypes: {
    url: "/lists/record-types/names",
    parse: (res: ListTypeRes) => res,
  },
  accounts: {
    url: "/admin/accounts/names",
    parse: (res: AccountNames) => res.data,
  },
  countries: {
    url: "/lists/countries/names",
    parse: (res: ListTypeRes) => res,
  },
  languages: {
    url: "/lists/languages/names",
    parse: (res: ListTypeRes) => res,
  },
  vehicles: {
    url: "/lists/vehicles/names",
    parse: (res: ListTypeRes) => res,
  },
  riders: {
    url: "/admin/riders/names",
    parse: (res: { names: RiderAccountName[] }) => res.names,
  },
};

const useLists = create<ListsStore>((set, get) => ({
  cities: [],
  getLists: async (listTypes: ListsReqConfig[]) => {
    for (const listType of listTypes) {
      const lists = get();

      // @ts-expect-error will be fixed later
      const listTypeLoadingState = lists[listType.type + "Loading"];

      if (
        (listType.force || !lists[listType.type]?.length) &&
        !listType.disabled &&
        !listTypeLoadingState
      ) {
        set({ [listType.type + "Loading"]: true });
        const config = reqLists[listType.type];
        type ParseArgType = Parameters<typeof config.parse>[0];
        const res = await getData<ParseArgType>(config.url);
        set({ [listType.type + "Loading"]: false });

        if (!res.isError && res.data) {
          // @ts-expect-error will be fixed later
          const parsedData = config.parse(res.data);
          set({ [listType.type]: parsedData });
        }
      }
    }
  },
  setCities: (cities) => set({ cities }),

  recordTypes: [],
  setRecordTypes: (recordTypes) => set({ recordTypes }),

  accounts: [],
  setAccounts: (accounts) => set({ accounts }),

  countries: [],
  countriesLoading: false,
  setCountries: (countries) => set({ countries }),

  languages: [],
  setLanguages: (languages) => set({ languages }),

  vehicles: [],
  setVehicles: (vehicles) => set({ vehicles }),

  riders: [],
  setRiders: (riders) => set({ riders }),
}));

export default useLists;
