import { Dropdown, Button } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineGlobal } from "react-icons/ai";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const languageKeyNameMapping = {
    en: "English",
    ar: "العربية",
    ckb: "کوردی",
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: "English",
            key: "english",
            onClick: () => changeLanguage("en"),
          },
          {
            label: "العربية",
            key: "العربية",
            onClick: () => changeLanguage("ar"),
          },
          {
            label: "کوردی",
            key: "کوردی",
            onClick: () => changeLanguage("ckb"),
          },
        ],
      }}
      trigger={["click"]}
    >
      <Button
        icon={<AiOutlineGlobal size={16} />}
        className="flex flex-row items-center gap-2 rounded-3xl p-5"
      >
        {languageKeyNameMapping[i18n.language as "ckb" | "en" | "ar"] ||
          languageKeyNameMapping["en"]}
      </Button>
    </Dropdown>
  );
};

export default LanguageSwitcher;
