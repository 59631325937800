import { useEffect, useState } from "react";
import { Badge, Button, Input, Modal, Popconfirm, Table, Tooltip } from "antd";
import { TableProps } from "antd";
import moment from "moment-timezone";
import PocketBase from "pocketbase";
import { BiMessageDetail } from "react-icons/bi";
import ViewImageModal from "../../common/ViewImageModal";
import { AiOutlineCheck, AiOutlinePicture } from "react-icons/ai";
import { toast } from "react-toastify";
import RiderCard from "../../common/RiderCard";
import { GrDocumentUser } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import { generateCSV } from "../../../utils/CSVUtils";
import ClickToCopy from "../../UI/ClickToCopy";
import RiderProfilePicIcon from "../../common/RiderProfilePicIcon";
import { VscSend } from "react-icons/vsc";
import useUser from "../../../store/User";
import { Navigate } from "react-router-dom";

interface RemoveSuspensionRecord {
  collectionId: string;
  collectionName: string;
  created: string;
  id: string;
  partner_id: string;
  rejection_reason: string;
  rider_city: string;
  rider_name: string;
  rider_picture: string;
  status: string;
  suspention_screenshot: string;
  updated: string;
}

interface RemoveSuspensionResponse {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  items: RemoveSuspensionRecord[];
}

const getRemoveSuspensionStatus = (status: string) => {
  switch (status) {
    case "pending":
      return "#fbbd23";
    case "sent":
      return "#3b82f6";
    case "solved":
      return "#36d399";
    case "rejected":
      return "#e11d48";
  }
};

const RemoveSuspension = () => {
  const { user } = useUser();
  const [data, setData] = useState<RemoveSuspensionResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(30);

  const [showViewImageModal, setShowViewImageModal] = useState({
    imgSrc: "",
    show: false,
    title: "",
  });

  const [showRiderInfo, setShowRiderInfo] = useState({
    show: false,
    partner_id: 0,
  });

  const [showRejectModal, setShowRejectModal] = useState({
    show: false,
    id: 0,
  });

  const pb = new PocketBase("https://saas.fullship.net");
  const fsRemoveSuspensionCollection = pb.collection(
    "FS_App_Remove_Suspention"
  );

  const fetchData = async () => {
    setIsLoading(true);
    const result: RemoveSuspensionResponse =
      await fsRemoveSuspensionCollection.getList(
        pagination,
        (pagination - 1) * pageSize,
        { sort: "-created" }
      );
    setData(result);
    setIsLoading(false);
  };

  const refetch = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: TableProps["columns"] = [
    {
      title: "Partner ID",
      dataIndex: "partner_id",
      key: "partner_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Badge
          color={getRemoveSuspensionStatus(status)}
          count={status}
          className="w-full text-center capitalize min-w-[70px]"
          showZero
        />
      ),
    },
    {
      title: "Rider",
      dataIndex: "rider_name",
      key: "rider_name",
      render: (_, row) => (
        <div className="flex flex-row items-center gap-1">
          <div>
            <RiderProfilePicIcon
              imageURL={row.rider_picture}
              onClick={() => {
                if (row.rider_picture) {
                  setShowViewImageModal({
                    title: `${row.rider_name} Profile Pic`,
                    imgSrc: row.rider_picture || "",
                    show: true,
                  });
                }
              }}
            />
          </div>
          <div>
            <ClickToCopy text={row.rider_name}>
              <div className={`text-left`}>{row.rider_name}</div>
            </ClickToCopy>
          </div>
        </div>
      ),
    },
    {
      title: "Rider City",
      dataIndex: "rider_city",
      key: "rider_city",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (val) => moment(val).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: "Actions",
      render: (_, row) => {
        const url = pb.files.getUrl(row, row.suspention_screenshot, {
          thumb: "100x250",
        });

        return (
          <div className="flex flex-row items-center justify-end gap-1">
            {row.status === "pending" && (
              <Popconfirm
                placement="bottomLeft"
                title="Change the status to sent"
                description="Are you sure you sent this report to partner?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  fsRemoveSuspensionCollection
                    .update(row.id, { status: "sent" })
                    .then(() => {
                      refetch();
                      toast.success("status changed to sent");
                    })
                    .catch(() => {
                      toast.error("Failed to change the status");
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <VscSend size={14} />
                </Button>
              </Popconfirm>
            )}

            {row.status === "sent" && (
              <Popconfirm
                placement="bottomLeft"
                title="Change the status to solve"
                description="Are you sure this is solved?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  fsRemoveSuspensionCollection
                    .update(row.id, { status: "solved" })
                    .then(() => {
                      refetch();
                      toast.success("status changed to solved");
                    })
                    .catch(() => {
                      toast.error("Failed to change the status");
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <AiOutlineCheck size={14} />
                </Button>
              </Popconfirm>
            )}

            {row.status === "sent" && (
              <Button
                onClick={() => {
                  setShowRejectModal({ show: true, id: row.id });
                }}
              >
                reject
              </Button>
            )}
            {row.suspention_screenshot && (
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {
                  setShowViewImageModal({
                    imgSrc: url,
                    show: true,
                    title: "Suspension Screenshot",
                  });
                }}
              >
                <AiOutlinePicture size={16} />
              </Button>
            )}

            {row.rejection_reason && (
              <Tooltip title="View Rejection Reason">
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "Rejection Reason",
                      content: row.rejection_reason,
                      centered: true,
                      closable: true,
                      maskClosable: true,
                    })
                  }
                  className="flex items-center px-3 py-2"
                >
                  <BiMessageDetail size={16} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title="View Rider Info">
              <Button
                onClick={() =>
                  setShowRiderInfo({ show: true, partner_id: row.partner_id })
                }
                className="flex items-center px-3 py-2"
              >
                <GrDocumentUser size={16} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState<RemoveSuspensionRecord[]>(
    []
  );

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: RemoveSuspensionRecord[]) => {
      setSelectedRows(selectedRows);
    },
  };

  const [rejectionReason, setRejectionReason] = useState("");

  if (user?.cityID != 1) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Modal
        title={`Rider Info: ${showRiderInfo.partner_id}`}
        centered
        open={showRiderInfo.show}
        footer={null}
        onCancel={() => {
          setShowRiderInfo({ show: false, partner_id: 0 });
        }}
        styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
        width="650px"
      >
        <RiderCard
          show={showRiderInfo.show}
          id={showRiderInfo.partner_id}
          id_type="partner_id"
        />
      </Modal>
      <Modal
        title={"Reject Remove Suspension"}
        centered
        open={showRejectModal.show}
        footer={null}
        onCancel={() => {
          setShowRejectModal({ show: false, id: 0 });
        }}
        styles={{
          body: { maxHeight: "70vh", overflowY: "auto" },
        }}
        width="400px"
      >
        <div className="pt-3 pb-5">
          <Input.TextArea
            onChange={(e) => {
              setRejectionReason(e.target.value);
            }}
            value={rejectionReason}
            rows={5}
          />
        </div>
        <div className="flex justify-end w-full">
          <Button
            type="primary"
            className="bg-primary"
            loading={isLoading}
            onClick={() => {
              if (!rejectionReason.trim()) {
                toast.warn("You should write a rejection reason");
                return;
              }
              setIsLoading(true);
              fsRemoveSuspensionCollection
                .update(showRejectModal.id + "", {
                  status: "rejected",
                  rejection_reason: rejectionReason,
                })
                .then(() => {
                  refetch();
                  toast.success("status changed to rejected");
                })
                .catch(() => {
                  toast.error("Failed to change the status");
                })
                .finally(() => {
                  setShowRejectModal({ show: false, id: 0 });
                  setIsLoading(false);
                  setRejectionReason("");
                });
            }}
          >
            Reject
          </Button>
        </div>
      </Modal>
      <ViewImageModal
        show={showViewImageModal.show}
        onHide={() => {
          setShowViewImageModal({ imgSrc: "", show: false, title: "" });
        }}
        title={showViewImageModal.title}
        imgSrc={showViewImageModal.imgSrc}
        local
        imageProps={{
          crossOrigin:
            showViewImageModal.title === "Suspension Screenshot"
              ? "anonymous"
              : "use-credentials",
        }}
      />
      <div className="flex flex-row justify-end mb-4">
        <Tooltip title={"Export rider feedbacks"}>
          <Button
            onClick={() => {
              const selectedDataToGenerated = selectedRows?.length
                ? selectedRows
                : data?.items;
              generateCSV(
                selectedDataToGenerated?.map((d) => {
                  return {
                    partner_id: d.partner_id,
                    rider_name: d.rider_name,
                    rider_city: d.rider_city,
                    status: d.status,
                    created: moment(d.created).format("YYYY-MM-DD hh:mm:ss A"),
                  };
                }) || [],
                "remove-suspension"
              );
            }}
            block
            className="btn-height-40 items-center rounded-border-xl-cstm max-w-[90px]"
            icon={<BsDownload size={16} />}
          />
        </Tooltip>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data?.items || []}
        pagination={{
          current: pagination,
          pageSize,
          total: data?.totalItems,
          onChange: (page) => setPagination(page),
          showSizeChanger: true,
          onShowSizeChange: (_, size) => {
            setPageSize(size);
            refetch();
          },
        }}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
      />
    </div>
  );
};

export default RemoveSuspension;
