import moment, { Moment } from "moment-timezone";
import { useTimezone } from "../context/TimezoneContext";

const DEFAULT_TIMEZONE = "Asia/Baghdad";

const getTimezoneOffset = (timezone: string): string => {
  const offset = moment.tz(timezone).format("Z");
  return `UTC${offset}`;
};

export const useMomentTZ = () => {
  const timez = useTimezone();

  const formatDateTimeWithTimezone = (
    date?: string | Date | Moment
  ): string => {
    const formattedDate = moment(date).format("YYYY-MM-DD hh:mm A");
    const timezoneOffset = getTimezoneOffset(
      timez?.timezone || DEFAULT_TIMEZONE
    );
    return `${formattedDate} ${timezoneOffset}`;
  };
  return { formatDateTimeWithTimezone };
};
