import axios, { AxiosError } from "axios";
// import * as Sentry from "@sentry/react";
import { showRateLimitModal } from "../components/UI/RateLimit";
import { Modal } from "antd";

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

// instance.interceptors.request.use(
//   (config) => {

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error: AxiosError) => {
    if (error.response?.status == 429) {
      showRateLimitModal(40, Modal.warn, () => {
        Modal.destroyAll();
      });
    }

    // if (
    //   error?.response?.status !== 401 &&
    //   error?.response?.status !== 403 &&
    //   error?.response?.status !== 429
    // ) {
    //   error.name =
    //     error?.response?.status +
    //     " | " +
    //     error?.name +
    //     " | " +
    //     error.config?.url;
    //   error.message = JSON.stringify(error?.response?.data);
    //   Sentry.captureException(error);
    // }
    return Promise.reject(error);
  }
);

export { instance as axiosInstance };
