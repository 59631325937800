import PerformanceCalculationSettingsPage from "./PerformanceCalculationSettingsPage";
import PerformanceGeneralSettingsPage from "./PerformanceGeneralSettingsPage";

const PerformanceSettings = () => {
  return (
    <div className="flex flex-col gap-5">
      <PerformanceGeneralSettingsPage />
      <PerformanceCalculationSettingsPage />
    </div>
  );
};

export default PerformanceSettings;
