import { useState } from "react";
import { del, get, post, put } from "../services";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import useGlobalLoading from "../store/GlobalLoading";
import useUser from "../store/User";

const { error: ErrorModal, destroyAll } = Modal;

interface AxiosProps<T> {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  payload?: object;
  config?: AxiosRequestConfig;
  onSuccess?: (data: AxiosResponse<T>) => void;
  onError?: (error: { message: string; data?: AxiosResponse<T> }) => void;
  onEnd?: () => void;
  notify?: boolean;
  globalLoading?: boolean;
  searchQuery?: string;
  disabled?: boolean;
}

interface Props {
  disabledStates?: boolean;
}

const useAxios = ({ disabledStates = false }: Props) => {
  const { setGlobalLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { setUser, setIsAuth } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const fetch = async <T>({
    url,
    method,
    payload,
    config,
    onSuccess,
    onEnd,
    onError,
    notify = true,
    globalLoading = false,
    searchQuery,
    disabled,
  }: AxiosProps<T>): Promise<AxiosResponse<T> | false> => {
    if (disabled) {
      return false;
    }
    const searchQueryObject = searchQuery ? JSON.parse(searchQuery) : null;
    let searchParamsString = "";
    if (searchQueryObject) {
      searchParamsString =
        "?" +
        Object.keys(searchQueryObject)
          .map((key) => {
            const keyValue =
              searchQueryObject[key as keyof typeof searchQueryObject];
            if (
              keyValue !== null &&
              keyValue !== undefined &&
              keyValue !== ""
            ) {
              return `${key}=${keyValue}`;
            }
            return null;
          })
          .filter(Boolean)
          .join("&");
    }

    const fullURL = url + searchParamsString;
    try {
      let response: AxiosResponse<T>;
      if (!disabledStates) {
        setIsLoading(true);
      }
      if (globalLoading) {
        setGlobalLoading(true);
      }
      switch (method) {
        case "GET":
          response = await get(fullURL, { withCredentials: true, ...config });
          break;
        case "POST":
          if (!payload) throw new Error("Payload is required");
          response = await post(fullURL, payload, {
            withCredentials: true,
            ...config,
          });
          break;
        case "PUT":
          if (!payload) throw new Error("Payload is required");
          response = await put(fullURL, payload, {
            withCredentials: true,
            ...config,
          });
          break;
        case "DELETE":
          response = await del(fullURL, { withCredentials: true, ...config });
          break;
      }

      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse =
          error.response?.data?.message || "Unknown error occurred";
        onError && onError({ message: errorResponse, data: error.response });
        notify && toast.error(errorResponse);
        if (
          error.response?.status == 401 &&
          window.location.pathname !== "/login"
        ) {
          ErrorModal({
            centered: true,
            title: "You are not authenticated",
            content:
              "Your token is expired or you are not authenticated, Please Login.",
            onOk: () => {
              setUser(null);
              setIsAuth(false);
              destroyAll();
              navigate("/login");
              window.location.reload();
            },
            className: "ant-error-modal",
          });
        }
        return false;
      }

      const errorResponse = (error as Error).message;
      onError && onError({ message: errorResponse });
      notify && toast.error(errorResponse);
      return false;
    } finally {
      onEnd && onEnd();
      !disabledStates && setIsLoading(false);
      if (globalLoading) {
        setGlobalLoading(false);
      }
    }
  };

  return { fetch, isLoading };
};

export default useAxios;
