import { Button, Col, Form, FormProps, Input, Modal, Row, Select } from "antd";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useAxios from "../../../../hooks/useAxios";
import { CityList, Countries } from "../../../../models/interfaces";
import useFetch from "../../../../hooks/useFetch";
import { filterOption } from "../../../../utils/antdUtils";

interface CityListFormProps {
  show: boolean;
  data?: CityList;
  onHide: () => void;
  refetch: () => void;
}

interface FieldType {
  name: string;
  country: string;
}
const CityListForm = ({ show, data, onHide, refetch }: CityListFormProps) => {
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const { data: countries } = useFetch<Countries>({
    url: "/lists/countries",
    searchQuery: JSON.stringify({
      _limit: 500,
    }),
    method: "GET",
    disabled: !show,
  });

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      url: data?.id ? `/lists/cities/${data.id}` : "/lists/cities",
      method: data?.id ? "PUT" : "POST",
      payload: {
        ...values,
      },
      onSuccess: () => {
        onHide();
        form.resetFields();
        refetch();
        toast.success(
          data?.id ? "City Updated Successfully" : "City Added Successfully"
        );
      },
    });
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    } else {
      form.setFieldValue("name", data?.name);
      form.setFieldValue("country", data?.countries?.alpha);
    }
  }, [show, form, data]);

  return (
    <Modal
      title={data?.id ? "Update City" : "Add New City"}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      centered
      open={show}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="City"
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="Country"
              name="country"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                options={countries?.data?.map((c) => ({
                  label: c.name,
                  value: c.alpha,
                }))}
                filterOption={filterOption}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end w-full">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {data?.id ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CityListForm;
