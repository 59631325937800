import { Card } from "antd";
import PerformanceGeneralSettingsForm from "./PerformanceGeneralSettingsForm";
import Loading from "../../../common/Loading";
import useFetch from "../../../../hooks/useFetch";
import { PerformanceGeneralSettingsRes } from "../../../../models/performance";

const PerformanceGeneralSettingsPage = () => {
  const { data, isLoading, refetch } = useFetch<PerformanceGeneralSettingsRes>({
    url: "/admin/riders/performance/settings/general",
    method: "GET",
    isLoadingDefault: true,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card title="General Settings">
      <PerformanceGeneralSettingsForm
        refetch={refetch}
        data={data?.generalSettings}
      />
    </Card>
  );
};

export default PerformanceGeneralSettingsPage;
