import { Badge, Button, Popconfirm, TableProps } from "antd";
import Table from "../../components/UI/Table";
import useFetch from "../../hooks/useFetch";
import { ErrorLog, ErrorLogsList } from "../../models/interfaces";
import { useState } from "react";
import MoreErrorInfoModal from "../../components/SuperPanel/ErrorLogs/MoreErrorInfoModal";
import { FaEye, FaRegFaceSadTear } from "react-icons/fa6";
import { GrTools } from "react-icons/gr";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { useMomentTZ } from "../../hooks/useMomentTZ";

const ErrorLogs = () => {
  const { formatDateTimeWithTimezone } = useMomentTZ();
  const { fetch } = useAxios({ disabledStates: true });

  const errorLogsPsl = localStorage.getItem("errorLogs-psl");
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(errorLogsPsl ? +errorLogsPsl : 10);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
  });

  const [showMoreErrorInfoModal, setShowMoreErrorInfoModal] = useState({
    show: false,
    payload: "",
    stack: "",
  });
  const { data, isLoading, refetch } = useFetch<ErrorLogsList>({
    url: `/admin/error-logs`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
      _sort: "id",
      _order: "desc",
    }),
    method: "GET",
    isLoadingDefault: true,
  });

  const columns: TableProps<ErrorLog>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sorter: true,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "reqType",
      title: "HTTP Method",
      dataIndex: "reqType",
    },
    {
      key: "operational",
      title: "Operational",
      dataIndex: "operational",
      render: (value) => (
        <Badge
          count={value ? "Yes" : "No"}
          showZero
          color={`${value ? "#22bb33" : "text-danger"}`}
        />
      ),
    },
    {
      key: "route",
      title: "Route",
      dataIndex: "route",
    },

    {
      key: "message",
      title: "Message",
      dataIndex: "message",
    },
    {
      key: "frequency",
      title: "Frequency",
      dataIndex: "frequency",
      sorter: true,
    },
    {
      key: "fixed",
      title: "Fixed",
      dataIndex: "fixed",
      render: (value) => (
        <Badge
          count={value ? "Yes" : "No"}
          showZero
          color={`${value ? "#22bb33" : "text-danger"}`}
        />
      ),
    },
    {
      key: "occurredAt",
      title: "Occurred At",
      dataIndex: "occurredAt",
      render: (value) => formatDateTimeWithTimezone(value),
      sorter: true,
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => (
        <div className="flex flex-row items-center justify-end gap-1">
          {!row.fixed && (
            <Popconfirm
              placement="bottomLeft"
              title="Is This Error Fixed ?"
              description="Are you sure this error is fixed ?"
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                fetch({
                  url: `/admin/error-logs/${row.id}`,
                  method: "PUT",
                  payload: { fixed: true },
                  onSuccess: () => {
                    refetch();
                    toast.success("Error Log Updated Successfully");
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {}}
              >
                <GrTools size={14} />
              </Button>
            </Popconfirm>
          )}
          {row.fixed && (
            <Popconfirm
              placement="bottomLeft"
              title="Is This Error Not Fixed ?"
              description="Are you sure this error is not fixed ?"
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                fetch({
                  url: `/admin/error-logs/${row.id}`,
                  method: "PUT",
                  payload: { fixed: false },
                  onSuccess: () => {
                    refetch();
                    toast.success("Error Log Updated Successfully");
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {}}
              >
                <FaRegFaceSadTear size={14} />
              </Button>
            </Popconfirm>
          )}
          <Button
            className="flex items-center px-3 py-2"
            onClick={() => {
              setShowMoreErrorInfoModal({
                show: true,
                payload: JSON.stringify(row.payload),
                stack: row.stack,
              });
            }}
          >
            <FaEye size={14} />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <MoreErrorInfoModal
        show={showMoreErrorInfoModal.show}
        payload={showMoreErrorInfoModal.payload}
        stack={showMoreErrorInfoModal.stack}
        onHide={() => {
          setShowMoreErrorInfoModal({ show: false, payload: "", stack: "" });
        }}
      />
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data ? data.data : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("errorLogs-psl", size.toString());
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        filters={{
          config: [
            {
              type: "selection",
              label: "Fixed",
              name: "fixed",
              selectionProps: {
                options: [
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ],
              },
            },
            {
              type: "selection",
              label: "Operational",
              name: "operational",
              selectionProps: {
                options: [
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ],
              },
            },
            {
              type: "date",
              label: "Occurred at",
              name: "occurred_at_",
            },
            {
              type: "date",
              label: "Occurred at",
              name: "occurred_at_",
            },
          ],
        }}
        onFilterValuesChange={(_, values, filters) => {
          let searchResult = {
            search: values.searchText,
            _sort: "",
            _order: "",
          };

          if (values?.sort?.column) {
            searchResult = {
              ...searchResult,
              _sort: values.sort.column,
              _order: values.sort.type,
            };
          }

          setSearchQuery({ ...searchResult, ...filters });
        }}
      />
    </div>
  );
};

export default ErrorLogs;
