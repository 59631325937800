import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Pagination,
  Popover,
  Radio,
  Row,
  Space,
  Table as TableComp,
  TableProps,
  Tooltip,
} from "antd";
import {
  Fragment,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoArrowDownOutline, IoReload, IoSearchOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import CSVProcessor from "../CSVMapping/CSVProcessor";
import DynamicFilterModal, {
  DynamicDefaultFilter,
  FilterConfig,
  FilterFormatType,
} from "../common/DynamicFilterModal";
import TruncateText from "../common/TruncateText";
import { BsDownload } from "react-icons/bs";
import { LuFilterX } from "react-icons/lu";
import { useTranslation } from "react-i18next";

interface FieldType {
  firstPopoverFilters: string;
  secondPopoverFilters: string;
  searchText: string;
  sort?: {
    type: "ASC" | "DESC";
    column: string;
  };
}

interface TablePropsComp {
  columns: TableProps["columns"];
  data: object[];
  loading?: boolean;
  pagination?: boolean;
  total?: number;
  pageSize?: number;
  currentPage?: number;
  onPaginationChange?: (page: number) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  filter?: boolean;
  Buttons?: {
    onClick: () => void;
    label: ReactNode;
    key: string;
    hidden?: boolean;
    btnRef?: RefObject<HTMLButtonElement>;
  }[];
  refetch?: () => void;
  firstPopoverFilters?: {
    text: string;
    filters: { label: string; value: string }[];
    initialValue: string;
  };
  secondPopoverFilters?: {
    text: string;
    filters: { label: string; value: string }[];
    initialValue: string;
  };
  onFilterValuesChange?: (
    value: string,
    values: FieldType,
    filters?: object
  ) => void;
  otherTableProps?: TableProps;
  csvProps?: {
    onSubmit: (
      file: Blob,
      name: string,
      callback?: (res?: { [key: string]: number[] }) => void
    ) => void;
    dbColumns: string[];
    mappingLocalStorageKey: string;
    tooltipTitle: string;
    hideDateField?: boolean;
    hide?: boolean;
    dateFormat?: string;
  };
  filters?: {
    config: FilterConfig[];
    defaultFilters?: DynamicDefaultFilter;
    filterFormats?: FilterFormatType;
  };
  pageSizeOptions?: string[];
  extraFilterCompStart?: ReactNode;
  extraFilterCompEnd?: ReactNode;
  scrollYH?: string;
  exportBtn?: {
    onClick: () => void;
    title: string;
  };
}

const Table = ({
  columns,
  data,
  loading,
  pagination = false,
  total,
  onPaginationChange,
  pageSize,
  currentPage,
  onShowSizeChange,
  Buttons,
  firstPopoverFilters,
  secondPopoverFilters,
  onFilterValuesChange,
  filter = false,
  refetch,
  otherTableProps,
  csvProps,
  filters,
  extraFilterCompStart,
  extraFilterCompEnd,
  pageSizeOptions = ["10", "20", "30", "40", "50"],
  scrollYH = "calc(100vh - 270px)",
  exportBtn,
}: TablePropsComp) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formSearchText = Form.useWatch("searchText", form);
  const formValues = form.getFieldsValue();
  const filterButtonResetRef = useRef<HTMLButtonElement>(null);

  const [inFilterMode, setInFilterMode] = useState(false);
  const [dynamicFilterValues, setDynamicFilterValues] = useState<
    object | undefined
  >(filters?.defaultFilters);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (formSearchText) {
      timer = setTimeout(() => {
        onPaginationChange && onPaginationChange(1);
        onFilterValuesChange &&
          onFilterValuesChange(formSearchText, formValues, dynamicFilterValues);
        setInFilterMode(true);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [formSearchText]);

  return (
    <Fragment>
      <div className="w-full overflow-hidden">
        <Form
          onValuesChange={(value, values) => {
            if (!value.searchText) {
              onPaginationChange && onPaginationChange(1);
              onFilterValuesChange &&
                onFilterValuesChange(value, values, dynamicFilterValues);
              setInFilterMode(true);
            }
          }}
          form={form}
          name="basic"
          layout="vertical"
        >
          <Row
            gutter={[10, 0]}
            className={`${filter ? "justify-end" : "justify-start"} mb-4`}
          >
            {extraFilterCompStart && <Col span={3}>{extraFilterCompStart}</Col>}
            {Buttons && Buttons?.length === 1 && !Buttons[0].hidden && (
              <Col span={5}>
                <Button
                  onClick={Buttons[0].onClick}
                  block
                  className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2"
                  type="primary"
                  ref={Buttons[0].btnRef}
                >
                  <AiOutlinePlus size={20} /> {Buttons[0].label}
                </Button>
              </Col>
            )}

            {Buttons && Buttons?.length > 1 && (
              <Col span={5}>
                <Dropdown menu={{ items: Buttons }} trigger={["click"]}>
                  <Button
                    block
                    className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2"
                    type="primary"
                  >
                    Actions <IoArrowDownOutline />
                  </Button>
                </Dropdown>
              </Col>
            )}
            {filter && (
              <>
                <Col span={10} className="mr-auto flex-row-dir">
                  <Col span={19}>
                    <Form.Item name="searchText" className="m-0">
                      <Input
                        className="btn-height-40 items-center rounded-border-xl-cstm"
                        allowClear
                        autoComplete="off"
                        placeholder={t("search")}
                        prefix={
                          <IoSearchOutline size={22} style={{ opacity: 0.2 }} />
                        }
                      />
                    </Form.Item>
                  </Col>

                  {filters && (
                    <Col span={5}>
                      <DynamicFilterModal<object>
                        filters={filters.config}
                        defaultFilter={filters.defaultFilters}
                        filterFormats={filters.filterFormats}
                        onApply={(values) => {
                          setDynamicFilterValues(values);
                          onPaginationChange && onPaginationChange(1);
                          onFilterValuesChange &&
                            onFilterValuesChange("", formValues, values);
                          setInFilterMode(true);
                        }}
                        resetButtonRef={filterButtonResetRef}
                      />
                    </Col>
                  )}
                </Col>
                {firstPopoverFilters && (
                  <Col span={3}>
                    <Popover
                      trigger="click"
                      placement="bottom"
                      content={
                        <div
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              name="firstPopoverFilters"
                              style={{
                                transition: "all 0.3s",
                              }}
                              initialValue={firstPopoverFilters.initialValue}
                              className="m-0"
                            >
                              <Radio.Group className="flex flex-col">
                                {firstPopoverFilters &&
                                  firstPopoverFilters.filters.map((filter) => (
                                    <Radio
                                      key={filter.value}
                                      value={filter.value}
                                    >
                                      {filter.label}
                                    </Radio>
                                  ))}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                      }
                    >
                      <Button
                        block
                        className="btn-height-40 items-center rounded-border-xl-cstm"
                      >
                        <Space>
                          <p>{firstPopoverFilters.text}</p>
                          <IoArrowDownOutline />
                        </Space>
                      </Button>
                    </Popover>
                  </Col>
                )}
                {csvProps && !csvProps.hide && (
                  <Col span={2}>
                    <CSVProcessor
                      dbColumns={csvProps.dbColumns}
                      mappingLocalStorageKey={csvProps.mappingLocalStorageKey}
                      onSubmit={csvProps.onSubmit}
                      hideDateField={csvProps.hideDateField}
                      tooltipTitle={csvProps.tooltipTitle}
                      dateFormat={csvProps.dateFormat}
                    />
                  </Col>
                )}

                {exportBtn && (
                  <Col span={2}>
                    <Tooltip title={exportBtn.title}>
                      <Button
                        onClick={exportBtn.onClick}
                        block
                        className="btn-height-40 items-center rounded-border-xl-cstm"
                        icon={<BsDownload size={16} />}
                      />
                    </Tooltip>
                  </Col>
                )}

                {secondPopoverFilters && (
                  <Col span={3}>
                    <Popover
                      content={
                        <div
                          style={{
                            width: "120px",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              name="secondPopoverFilters"
                              style={{
                                transition: "all 0.3s",
                              }}
                              initialValue={secondPopoverFilters.initialValue}
                              className="m-0"
                            >
                              <Radio.Group className="flex flex-col">
                                {secondPopoverFilters &&
                                  secondPopoverFilters.filters.map((filter) => (
                                    <Radio
                                      key={filter.value}
                                      className="w-full"
                                      value={filter.value}
                                    >
                                      {filter.label}
                                    </Radio>
                                  ))}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                      }
                      trigger="click"
                      placement="bottom"
                    >
                      <Button
                        block
                        className="btn-height-40 items-center rounded-border-xl-cstm"
                      >
                        <Space>
                          <p>{secondPopoverFilters.text}</p>
                          <IoArrowDownOutline />
                        </Space>
                      </Button>
                    </Popover>
                  </Col>
                )}
                <Col span={2}>
                  <Tooltip title="Refresh">
                    <Button
                      onClick={() => {
                        refetch && refetch();
                      }}
                      block
                      className="btn-height-40 items-center rounded-border-xl-cstm"
                      icon={<IoReload size={16} />}
                    />
                  </Tooltip>
                </Col>
                {extraFilterCompEnd && <Col span={3}>{extraFilterCompEnd}</Col>}
                {inFilterMode && (
                  <Col span={3}>
                    <Tooltip title="Reset filters to default.">
                      <Button
                        danger
                        onClick={() => {
                          form.resetFields([
                            "searchText",
                            "firstPopoverFilters",
                            "secondPopoverFilters",
                          ]);
                          onPaginationChange && onPaginationChange(1);
                          onFilterValuesChange &&
                            onFilterValuesChange(
                              "",
                              {
                                firstPopoverFilters:
                                  firstPopoverFilters?.initialValue || "",
                                secondPopoverFilters:
                                  secondPopoverFilters?.initialValue || "",
                                searchText: "",
                              },
                              filters?.defaultFilters
                            );
                          filterButtonResetRef.current &&
                            filterButtonResetRef.current.click();
                          setInFilterMode(false);
                          setDynamicFilterValues(filters?.defaultFilters);
                        }}
                        block
                        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 btn-warning"
                      >
                        <LuFilterX size={16} /> Reset
                      </Button>
                    </Tooltip>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Form>
        <TableComp
          columns={columns?.map((col) => {
            const columnTitle = col?.title as string;

            return {
              ...col,
              title:
                columnTitle?.length < 14 ? (
                  <div className="text-nowrap">{columnTitle}</div>
                ) : (
                  <TruncateText text={columnTitle || ""} maxChars={8} />
                ),
              showSorterTooltip: columnTitle?.length < 14,
              width: col?.width || "100px",
            };
          })}
          dataSource={data}
          rowKey="id"
          pagination={false}
          loading={loading}
          onChange={(_, __, sorter) => {
            // @ts-expect-error types is not defined from the library
            if (sorter?.column) {
              const sortObj = {
                // @ts-expect-error types is not defined from the library
                type: sorter?.order === "descend" ? "desc" : "asc",
                // @ts-expect-error types is not defined from the library
                column: sorter?.columnKey,
              };

              onPaginationChange && onPaginationChange(1);
              onFilterValuesChange &&
                onFilterValuesChange(
                  "sort",
                  { ...formValues, sort: sortObj },
                  dynamicFilterValues
                );
            } else {
              onPaginationChange && onPaginationChange(1);
              onFilterValuesChange &&
                onFilterValuesChange(
                  "sort",
                  {
                    ...formValues,
                    sort: undefined,
                  },
                  dynamicFilterValues
                );
            }
          }}
          scroll={{ y: scrollYH, x: "max-content" }}
          {...otherTableProps}
        />
      </div>

      {(total || pagination) && (
        <div className="flex justify-between pt-2">
          <div className="font-semibold">
            {t("total")}: {total?.toLocaleString()}
          </div>
          {pagination && (
            <Pagination
              total={total}
              onChange={onPaginationChange}
              pageSize={pageSize}
              current={currentPage}
              showSizeChanger={true}
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={pageSizeOptions}
              locale={{
                items_per_page: "/ " + t("page"),
              }}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Table;
