import LeaveSettings from "../../components/SuperPanel/Settings/Leave";
import { Menu, MenuProps } from "antd";
import { BsSuitcase } from "react-icons/bs";
import { IoIosList } from "react-icons/io";
import { MdManageAccounts, MdOutlineSportsMotorsports } from "react-icons/md";
import { useState } from "react";
import Lists from "../../components/SuperPanel/Settings/Lists";
import Roles from "../../components/SuperPanel/Settings/Roles";
import RiderSettings from "../../components/SuperPanel/Settings/Rider";
import PerformanceSettings from "../../components/SuperPanel/Settings/Performance";
import { CgPerformance } from "react-icons/cg";
import { TbTool } from "react-icons/tb";
import SystemMode from "../../components/SuperPanel/Settings/SystemMode";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    label: "Roles",
    key: "roles",
    icon: <MdManageAccounts />,
  },
  {
    label: "Rider",
    key: "rider",
    icon: <MdOutlineSportsMotorsports />,
  },
  {
    label: "Leave",
    key: "leave",
    icon: <BsSuitcase />,
  },
  {
    label: "Lists",
    key: "lists",
    icon: <IoIosList />,
  },
  {
    label: "Performance",
    key: "performance",
    icon: <CgPerformance />,
  },
  {
    label: "System Mode",
    key: "mode",
    icon: <TbTool />,
  },
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState("roles");

  const onClick: MenuProps["onClick"] = (e) => {
    setActiveTab(e.key);
  };

  return (
    <div>
      <Menu
        onClick={onClick}
        selectedKeys={[activeTab]}
        mode="horizontal"
        items={items}
      />
      <div className="py-5 px-2">
        {activeTab === "leave" && <LeaveSettings />}
        {activeTab === "rider" && <RiderSettings />}
        {activeTab === "lists" && <Lists />}
        {activeTab === "roles" && <Roles />}
        {activeTab === "performance" && <PerformanceSettings />}
        {activeTab === "mode" && <SystemMode />}
      </div>
    </div>
  );
};

export default Settings;
