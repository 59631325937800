import React, { useRef, useState } from "react";
import { Tabs, Tour, TourProps } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUser from "../../../store/User";
import useAxios from "../../../hooks/useAxios";
import { hasViewAccess } from "../../../utils/authorizationUtils";
import { UserSettings } from "../../../models/interfaces";

const HiringProcessLayout = () => {
  const tabsRef = useRef(null);
  const { t } = useTranslation();
  const { user, userSettings, setUserSettings } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetch } = useAxios({ disabledStates: true });

  const [tourOpen, setTourOpen] = useState<{
    show: boolean;
    steps: TourProps["steps"];
  }>({
    show: true,
    steps: [
      {
        title: "New Hiring List Styles!",
        description:
          "Now you can easily navigate through hiring list steps here using this tabs.",
        target: () => tabsRef.current,
      },
    ],
  });
  const lastURLTab = location.pathname.split("/").pop();
  const activeTab =
    lastURLTab === "hiring-process" ? "waiting-list" : lastURLTab;

  const onTabChange = (key: string) => {
    // if (key === "hiring-process") {
    //   navigate(`/hiring-process`);
    //   return;
    // }
    navigate(`/hiring-process/${key}`);
  };

  const items = [
    // { label: "Overview", key: "hiring-process" },
    { label: t("waiting list"), key: "waiting-list" },
    {
      label: t("interview"),
      key: "interview",
      disabled: !hasViewAccess("IN", user),
    },
    { label: t("training"), key: "training" },
    { label: t("audit"), key: "audit" },
    { label: t("warehouse"), key: "warehouse" },
    { label: t("onboard"), key: "onboard" },
    { label: t("all"), key: "all" },
  ];

  return (
    <div>
      <div ref={tabsRef}>
        <Tabs
          activeKey={activeTab}
          onChange={onTabChange}
          items={items.filter((it) => !it.disabled)}
          centered
          type="card"
        />
      </div>
      {userSettings && (
        <Tour
          zIndex={100000}
          open={!userSettings?.tour?.hiringProcessTabs && tourOpen.show}
          onClose={() => setTourOpen({ show: false, steps: [] })}
          steps={tourOpen.steps}
          closable={false}
          onFinish={() => {
            fetch<{ setting: UserSettings }>({
              url: "/account/setting",
              method: "PUT",
              payload: {
                ...userSettings,
                tour: {
                  ...userSettings?.tour,
                  hiringProcessTabs: true,
                },
              },
              onSuccess: (res) => {
                setUserSettings(res?.data?.setting || {});
              },
            });
          }}
        />
      )}
      <Outlet />
    </div>
  );
};

export default React.memo(HiringProcessLayout);
