import { AxiosRequestConfig } from "axios";
import { axiosInstance as axios } from "../lib/axios-http";

const post = async (
  url: string,
  payload: object,
  config?: AxiosRequestConfig
) => {
  const response = await axios.post(url, payload, config);
  return response;
};

const put = async (
  url: string,
  payload: object,
  config?: AxiosRequestConfig
) => {
  const response = await axios.put(url, payload, config);
  return response;
};

const del = async (url: string, config?: AxiosRequestConfig) => {
  const response = await axios.delete(url, config);
  return response;
};

const get = async (url: string, config?: AxiosRequestConfig) => {
  const response = await axios.get(url, config);
  return response;
};

const request = async (
  url: string,
  method: string,
  payload?: object,
  config?: AxiosRequestConfig
) => {
  const response = await axios.request({
    data: payload,
    method,
    url,
    ...config,
  });
  return response;
};

export { post, put, del, get, request };
