import React from "react";
import { Button, Result } from "antd";
import { AiOutlineReload } from "react-icons/ai";
import BugReportForm from "../layouts/ReportBug";
import { useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  const handleReloadPage = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <Result
        status="error"
        title="Oops! Something Went Wrong"
        subTitle="Please try reloading the application or report the issue. The app will automatically reload after the bug report is sent."
        extra={
          <>
            <Button
              type="primary"
              className="flex flex-row items-center gap-2 rounded-3xl p-5"
              icon={<AiOutlineReload />}
              onClick={handleReloadPage}
            >
              Reload the App
            </Button>
            <BugReportForm onDone={handleReloadPage} />
          </>
        }
      />
    </div>
  );
};

export default ErrorPage;
