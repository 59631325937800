import Title from "antd/es/typography/Title";
import useFetch from "../hooks/useFetch";
import { Account as AccountTypes } from "../models/interfaces";
import {
  Button,
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import moment from "moment-timezone";
import useAxios from "../hooks/useAxios";
import { toast } from "react-toastify";
import { useState } from "react";
import { genders } from "../data/mockData";
import Loading from "../components/common/Loading";
import ChangePasswordModal from "../components/ForgetPassword/ChangePasswordModal";
import DatePicker from "../components/UI/DatePicker";
import UploadProfileImageModal from "../components/Accounts/UploadProfileImageModal";
import useListsReq from "../hooks/useListsReq";

const Account = () => {
  const { cities } = useListsReq({
    requestLists: [{ type: "cities" }],
  });

  const [form] = Form.useForm<AccountTypes>();

  const [updateView, setUpdateView] = useState(false);
  const { fetch, isLoading: isLoadingSubmitForm } = useAxios({
    disabledStates: false,
  });
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { isLoading, error, refetch, data } = useFetch<AccountTypes>({
    url: "/account",
    method: "GET",
    onSuccess: (data) => {
      if (data.data) {
        form.setFieldsValue({
          ...data.data,
          birthDate: moment(data.data.birthDate),
        });
      }
    },
  });

  const onFinish: FormProps<AccountTypes>["onFinish"] = async (values) => {
    await fetch<AccountTypes>({
      url: "/account",
      method: "PUT",
      payload: {
        birthDate: moment(values.birthDate).format("YYYY-MM-DD"),
        fullName: values.fullName,
        gender: values.gender,
        jobTitle: values.jobTitle,
      },
      onSuccess: async () => {
        toast.success("Account updated successfully");
      },
      onEnd: () => {
        refetch();
        setUpdateView(false);
      },
    });
  };

  if (isLoading) return <Loading />;

  if (error) return <div>{error}</div>;

  return (
    <div>
      <ChangePasswordModal
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
        userAccountInfo={data}
      />
      <Title level={3}>Account</Title>
      <UploadProfileImageModal
        id={data?.id}
        selectedFiles={[
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: import.meta.env.VITE_API_URL + "/" + data?.imageURL,
            crossOrigin: "use-credentials",
          },
        ]}
        refetch={refetch}
      />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<AccountTypes> label="ID" name="id">
              <InputNumber
                className="w-full"
                disabled={updateView}
                readOnly={!updateView}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Full Name"
              name="fullName"
              rules={[{ required: true }]}
            >
              <Input readOnly={!updateView} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Email"
              name="email"
              rules={[{ required: true }]}
            >
              <Input disabled={updateView} readOnly={!updateView} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Phone Number"
              name="phoneNumber"
              // rules={[{ required: true }]}
            >
              <Input disabled={updateView} readOnly={!updateView} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Gender"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select
                className="capitalize"
                open={!updateView ? false : undefined}
              >
                {genders.map((gender) => (
                  <Select.Option
                    className="capitalize"
                    key={gender.value}
                    value={gender.value}
                  >
                    {gender.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="City"
              name="cityID"
              // rules={[{ required: true }]}
            >
              <Select
                className="capitalize"
                open={!updateView ? false : undefined}
              >
                {cities?.map((city) => (
                  <Select.Option
                    className="capitalize"
                    key={city.id}
                    value={city.id}
                  >
                    {city.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Birth Date"
              name="birthDate"
              rules={[{ required: true }]}
            >
              <DatePicker
                className="w-full"
                inputReadOnly={!updateView}
                disabledDate={!updateView ? () => true : undefined}
                maxDate={moment().subtract(18, "years")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item<AccountTypes>
              label="Job Title"
              name="jobTitle"
              rules={[{ required: true }]}
            >
              <Input readOnly={!updateView} />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex items-center justify-end gap-3">
          {updateView && (
            <div className="flex justify-end">
              <Button
                htmlType="submit"
                type="primary"
                className="bg-primary"
                loading={isLoadingSubmitForm}
              >
                Update
              </Button>
            </div>
          )}
          {!updateView && (
            <div className="flex justify-end">
              <Button htmlType="button" onClick={() => setUpdateView(true)}>
                Edit
              </Button>
            </div>
          )}
          <div className="flex justify-end">
            <Button
              htmlType="button"
              onClick={() => setShowChangePasswordModal(true)}
            >
              Change password
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Account;
