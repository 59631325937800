import { useEffect } from "react";
import useLists from "../store/Lists";
import useUser from "../store/User";
import { hasViewAccess } from "../utils/authorizationUtils";

const ListFetcher = () => {
  const { getLists } = useLists();
  const { user, isAuth } = useUser();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isAuth) {
      intervalId = setInterval(() => {
        getLists([
          {
            type: "accounts",
            force: true,
            disabled: !hasViewAccess("AC", user),
          },
          {
            type: "riders",
            force: true,
          },
        ]);
      }, 15 * 60 * 1000);
    }

    return () => clearInterval(intervalId);
  }, [isAuth, user]);

  return null;
};

export default ListFetcher;
