import { Button, Popconfirm, TableProps } from "antd";
import { useState } from "react";
import Table from "../../../UI/Table";
import useFetch from "../../../../hooks/useFetch";
import { Role, RolesList } from "../../../../models/interfaces";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import useAxios from "../../../../hooks/useAxios";
import Drawer from "../../../UI/Drawer";
import AuthorizationComponent from "./AuthorizationComponent";

const Roles = () => {
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState({
    name: "",
  });

  const { fetch } = useAxios({ disabledStates: true });

  const [showDrawer, setShowDrawer] = useState<{
    show: boolean;
    data: Role | null;
  }>({ show: false, data: null });

  const onClose = () => {
    setShowDrawer({ show: false, data: null });
  };

  const { data, isLoading, refetch } = useFetch<RolesList>({
    url: `/admin/authorization`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
    }),
    method: "GET",
    isLoadingDefault: true,
  });

  const columns: TableProps<Role>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Linked Accounts",
      dataIndex: "linkCount",
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "110px",
      render: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowDrawer({ show: true, data: row });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title="Delete the role"
              description="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                fetch({
                  url: `/admin/authorization/${row.id}`,
                  method: "DELETE",
                  onSuccess: () => {
                    refetch();
                    toast.success("Role deleted successfully");
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button danger className="flex items-center px-3 py-2">
                <AiOutlineDelete size={14} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Drawer title="Add Role" onClose={onClose} open={showDrawer.show}>
        <AuthorizationComponent
          onCloseDrawer={onClose}
          drawerFlag={showDrawer.show}
          data={showDrawer.data}
          refetch={refetch}
        />
      </Drawer>

      <Table
        Buttons={[
          {
            key: "Add",
            label: "Add Role",
            onClick: () => {
              setShowDrawer({ show: true, data: null });
            },
          },
        ]}
        loading={isLoading || false}
        columns={columns}
        data={data ? data.data : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        onFilterValuesChange={(_, values) => {
          setSearchQuery({
            name: values.searchText,
          });
        }}
      />
    </>
  );
};

export default Roles;
