import moment from "moment-timezone";
import Papa from "papaparse";

export const downloadBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateCSV = (data: object[], fileName: string) => {
  const csv = Papa.unparse(data);

  const csvWithBOM = "\uFEFF" + csv;
  const blob = new Blob([csvWithBOM], { type: "text/csv; charset=utf-8;" });

  downloadBlob(blob, `${fileName}_${moment().format("DD-MM-YYYY")}.csv`);
};
