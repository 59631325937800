import { Button, Divider, Modal, TableProps, Typography } from "antd";
import Table from "../UI/Table";

interface CSVInvalidViewerProps {
  data: string[][];
  show: boolean;
  errorDetails: { [key: string]: number[] };
  onHide: () => void;
}

const CSVInvalidViewer = ({
  data,
  show,
  errorDetails,
  onHide,
}: CSVInvalidViewerProps) => {
  const csvColumns = data[0] as string[];
  const csvData = data.slice(1) as string[][];

  const renderErrorTables = () => {
    return Object.keys(errorDetails).map((key) => {
      // the row number from the server
      // is the csv actual row numbers (-1) which means except headers, but here in the frontend we are using array
      // and its without the headers, the array starts from 0 thats a (-1) and except headers its another (-1).

      // example: if we get back (400) row number invalid row from the server inside the CSV its (401) inside our array if we
      // want to find the index its index of (row number - 1) which the index (399).

      const invalidRows = errorDetails[key].map(
        (rowNumber) => csvData[rowNumber - 1]
      );

      const dynamicColumns: TableProps["columns"] = csvColumns.map(
        (col, index) => ({
          title:
            key === col ? (
              <strong className="text-[#e11d48]">{col}</strong>
            ) : (
              col
            ),
          dataIndex: index,
          key: col,
          render: (val: string) =>
            key === col ? (
              <strong className="text-[#e11d48]">{val}</strong>
            ) : (
              val
            ),
        })
      );

      const columns: TableProps["columns"] = [
        {
          title: "Invalid Row ID",
          dataIndex: "invalidRow",
          key: "invalidRow",
          fixed: "left",
          width: "100px",
        },
        ...dynamicColumns,
      ];
      return (
        <div key={key}>
          <Typography.Title className="mt-0 my-1" level={4}>
            {key}
          </Typography.Title>
          <Table
            data={invalidRows.map((row, index) => ({
              key: index,
              invalidRow: errorDetails[key][index] + 1,
              ...row,
            }))}
            columns={columns}
          />
          <Divider />
        </div>
      );
    });
  };

  return (
    <Modal
      title="Invalid CSV Rows"
      open={show}
      onCancel={onHide}
      centered
      width={"95%"}
      maskClosable={false}
      footer={
        <Button type="primary" onClick={onHide}>
          OK
        </Button>
      }
      styles={{
        body: { maxHeight: "80vh", overflow: "auto", padding: "0 10px" },
      }}
    >
      <div className="flex flex-col gap-0">{renderErrorTables()}</div>
    </Modal>
  );
};

export default CSVInvalidViewer;
