import { Button, Input } from "antd";
import classes from "./EmailCard.module.css";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { emailLabels } from "./EmailCard";

type input = { id: string; value: string }[];

interface EmailInputsProps {
  inputs: input;
  title?: string;
  subTitle?: string;
  onAddNewEmail: (label: emailLabels) => void;
  onRemoveEmail: (label: emailLabels, id: string) => void;
  onEmailValueChange: (label: emailLabels, id: string, value: string) => void;
  label: emailLabels;
}

const EmailInputs = ({
  inputs,
  title,
  subTitle,
  onAddNewEmail,
  onRemoveEmail,
  onEmailValueChange,
  label,
}: EmailInputsProps) => {
  return (
    <div className={classes.email}>
      {title && <div className={classes.emailRole}>{title}</div>}{" "}
      {subTitle && <div>{subTitle}</div>}
      <div className={classes.input}>
        <Input
          onChange={(e) => {
            const inputValue = e.target.value;
            onEmailValueChange(label, "email main", inputValue);
          }}
          value={inputs?.find((inp) => inp.id === "email main")?.value}
        />
        <Button
          onClick={() => {
            onAddNewEmail(label);
          }}
        >
          <AiOutlinePlus size={18} />
        </Button>
      </div>
      {inputs
        .filter((inpt) => inpt.id !== "email main")
        .map(({ id, value }) => {
          return (
            <div className={classes.input} key={id}>
              <Input
                onChange={(e) => {
                  const inputValue = e.target.value;
                  onEmailValueChange(label, id, inputValue);
                }}
                value={value}
              />
              <Button
                danger
                onClick={() => {
                  onRemoveEmail(label, id);
                }}
              >
                <AiOutlineDelete size={18} />
              </Button>
            </div>
          );
        })}
    </div>
  );
};

export default EmailInputs;
