import { useState } from "react";
import { Modal, Button, Typography, Tooltip } from "antd";
import { RiFileList3Line } from "react-icons/ri";
import { BsInfoLg } from "react-icons/bs";

const { Paragraph } = Typography;

const RulesAndRegulationsModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Office Rules and Regulations">
        <Button
          className="border-1 rounded-full p-3 flex items-center justify-center bg-primary"
          shape="circle"
          onClick={showModal}
        >
          <BsInfoLg color="#fbbd23" size={22} />
        </Button>
      </Tooltip>
      <Modal
        open={isModalVisible}
        title={
          <div className="flex items-center">
            <RiFileList3Line className="text-3xl mr-3 text-blue-500" />
            <span>Office Rules and Regulations</span>
          </div>
        }
        className="max-w-2xl"
        closable={false}
        maskClosable={false}
        centered
        footer={null}
        destroyOnClose
      >
        <div className="max-h-[80vh] overflow-y-auto pb-5">
          <div className="space-y-4">
            <Paragraph>
              <strong>1. Lunch Time:</strong> Each employee has 45 minutes lunch
              break should be between 01pm - 02pm.
            </Paragraph>
            <Paragraph>
              <strong>2. Lunch Food:</strong> Each employee is responsible for
              his/her lunch orders and company won't pay for the food, instead;
              the company will pay 75,000 IQD/month for each employee.
            </Paragraph>
            <Paragraph>
              <strong>3. Smoking Area:</strong> Any type of smoking (Cigarettes,
              Cigars, Vapes, Or Hookahs) are prohibited inside any Buildings of
              the company. Employees can smoke outdoors.
            </Paragraph>
            <Paragraph>
              <strong>4. Finance Room:</strong> No one should enter the finance
              rooms in any branch of the company if they are not permitted by
              the responsible managers.
            </Paragraph>
            <Paragraph>
              <strong>5. Store Room:</strong> No one should enter the store
              rooms in any branch of the company if they are not permitted by
              the responsible managers.
            </Paragraph>
            <Paragraph>
              <strong>6. Rider Doc Management:</strong> Tuesdays are dedicated
              to handle the riders' ID docs. The dedicated officer should be at
              a direct window for this thing. Receiving docs of new hires are
              every working date to ease the operations. Exemptions are
              considered accordingly.
            </Paragraph>
            <Paragraph>
              <strong>7. E-wallet accounts:</strong> Dedicated officers will
              create E-wallets for the new riders who have their IDs collected
              by the company earlier. The process will be on the same day of
              Document receipt.
            </Paragraph>
            <Paragraph>
              <strong>8. COD collections hours:</strong> The finance team is
              committed to collect cash from 09:00 am until 16:30 pm without
              closing the windows for lunch break. Their lunch breaks will be on
              rotation. On Sundays two cashiers should operate in order to
              reduce the queue time.
            </Paragraph>
            <Paragraph>
              <strong>9. Office working hours:</strong> It begins at 9:00 am,
              and employees are expected to be at the office by that time. A
              maximum delay of 30 minutes is allowed. If an employee arrives
              late, they should also check out at 5:30 pm. After 05:30 pm the
              office should be closed by the responsible person. Any uncompleted
              tasks should be completed the next day or requesting an exemption
              to stay longer.
            </Paragraph>
            <Paragraph>
              <strong>10. Housekeeping:</strong> Each employee is responsible
              for housekeeping his/her workplace. Furthermore, a cleaning
              company will do the periodic deep cleaning and the cleaning cost
              will be shared by the employees in the dedicated offices.
            </Paragraph>
          </div>
          <div className="flex justify-end">
            <Button key="ok" type="primary" className="" onClick={handleOk}>
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RulesAndRegulationsModal;
