import Table from "../../components/UI/Table";
import useFetch from "../../hooks/useFetch";
import { LogsList } from "../../models/interfaces";
import { useState } from "react";
import useLogsColumns from "./hooks/useLogsColumns";

const Logs = () => {
  const { columns, components } = useLogsColumns();
  const logsPsl = localStorage.getItem("logs-psl");
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(logsPsl ? +logsPsl : 10);

  const [searchQuery, setSearchQuery] = useState({
    action: "",
    search: "",
    section: "",
  });

  const { data, isLoading, refetch } = useFetch<LogsList>({
    url: `/logs`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
      _sort: "id",
      _order: "desc",
    }),
    method: "GET",
    isLoadingDefault: true,
  });

  return (
    <div>
      {components}
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data ? data.users : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("logs-psl", size.toString());
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        firstPopoverFilters={{
          text: "Type",
          filters: [
            { label: "All", value: "" },
            { label: "Get", value: "get" },
            { label: "Insert", value: "insert" },
            { label: "Update", value: "update" },
            { label: "Delete", value: "delete" },
            { label: "Save", value: "save" },
            { label: "Other", value: "other" },
          ],
          initialValue: "",
        }}
        secondPopoverFilters={{
          text: "Section",
          filters: [
            { label: "All", value: "" },
            { label: "Accounts", value: "accounts" },
            { label: "Backup", value: "backup" },
            { label: "Riders", value: "riders" },
            { label: "Leaves", value: "leaves" },
            { label: "Settings", value: "settings" },
            { label: "Waiting List", value: "waitingList" },
            { label: "Error Logs", value: "errorLogs" },
            { label: "Performance", value: "performance" },
          ],
          initialValue: "",
        }}
        onFilterValuesChange={(_, values) => {
          setSearchQuery({
            action: values.firstPopoverFilters,
            section: values.secondPopoverFilters,
            search: values.searchText,
          });
        }}
      />
    </div>
  );
};

export default Logs;
