import { Outlet } from "react-router-dom";
import useTheme from "../../store/Theme";

const AnonymousLayout = () => {
  const { theme } = useTheme();

  return (
    <div
      className={`h-screen flex justify-center items-center ${
        theme === "dark" ? "bg-black" : "bg-gray-100"
      }`}
    >
      <Outlet />
    </div>
  );
};

export default AnonymousLayout;
