import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import Table from "../../UI/Table";
import { Button, Popconfirm, TableProps } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import { LiaDownloadSolid } from "react-icons/lia";
import { downloadBlob } from "../../../utils/CSVUtils";

interface BackupTableProps {
  refetch: () => void;
  data: string[];
  type: string;
  deleteBackupOption?: boolean;
}

const BackupTable = ({
  type,
  data,
  refetch,
  deleteBackupOption = true,
}: BackupTableProps) => {
  const backupType = type.toLowerCase();
  const { fetch } = useAxios({ disabledStates: true });

  const restoreBackup = (backup: string, type: string) => {
    fetch({
      url: `/admin/backup/restore`,
      method: "POST",
      payload: {
        type: type,
        filename: backup,
      },
      onSuccess: () => {
        refetch();
        toast.success("Backup restored successfully");
      },
      globalLoading: true,
    });
  };
  const downloadBackup = (backup: string, type: string) => {
    fetch<ArrayBuffer>({
      url: `/admin/backup/download/${type}/${backup}`,
      method: "POST",
      payload: {},
      config: {
        responseType: "blob",
        headers: {},
      },
      onSuccess: (response) => {
        downloadBlob(new Blob([response.data]), backup);
        refetch();
        toast.success("Backup downloaded successfully");
      },
      globalLoading: true,
    });
  };
  const deleteBackup = (backup: string, type: string) => {
    fetch({
      url: `/admin/backup/${type}/${backup}`,
      method: "DELETE",
      onSuccess: () => {
        refetch();
        toast.success("Backup Deleted successfully");
      },
      globalLoading: true,
    });
  };
  const tableColumns: TableProps<{ id: string; backup: string }>["columns"] = [
    {
      dataIndex: "#",
      title: "#",
      key: "#",
      render: (_, __, index) => <div>{index + 1}</div>,
      width: "65px",
    },
    { title: `${type} Backups`, dataIndex: "backup", key: "backup" },
    {
      title: "",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-3">
            {deleteBackupOption && (
              <Popconfirm
                placement="bottomLeft"
                title="Delete this backup"
                description="Are you sure to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deleteBackup(row.backup, backupType);
                }}
                okButtonProps={{ className: "bg-primary" }}
              >
                <Button danger className="flex items-center">
                  <AiOutlineDelete size={16} />
                </Button>
              </Popconfirm>
            )}
            <Popconfirm
              placement="bottomLeft"
              title="Restore this backup"
              description="Are you sure to restore this?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                restoreBackup(row.backup, backupType);
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button className="flex items-center">
                <IoReload size={16} />
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title="Download this backup"
              description="Are you sure to download this?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                downloadBackup(row.backup, backupType);
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button className="flex items-center">
                <LiaDownloadSolid size={16} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      loading={false}
      columns={tableColumns}
      data={
        (data &&
          data.map((backup) => ({
            id: backup,
            backup,
          }))) ||
        []
      }
      pagination={false}
      filter={false}
      otherTableProps={{ scroll: { y: 500 } }}
    />
  );
};

export default BackupTable;
