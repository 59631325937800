import React, { useState } from "react";
import { Select, Button, Form, Card, Row, Col } from "antd";
import {
  Access,
  AccessType,
  accessTypeOptions,
  AccessView,
  Authorization,
  ListTypeRes,
  Sections,
} from "../../../../models/interfaces";
import AccessComponent from "./AccessComponent";
import { filterOption } from "../../../../utils/antdUtils";
import { PiTrashSimple } from "react-icons/pi";
import { AiOutlinePlus } from "react-icons/ai";

const sectionNames = {
  AC: "Accounts",
  RI: "Riders",
  LE: "Leaves",
  WL: "Waiting List",
  IN: "Interview",
  PE: "Performance",
  FI: "Finance",
  "ONLY-SUPER": "ONLY-SUPER",
};
interface SectionComponentProps {
  sectionIndex: number;
  sectionData: Authorization;
  onAddAccess: (sectionIndex: number) => void;
  onUpdateAccess: (
    sectionIndex: number,
    accessIndex: number,
    updatedAccess: Access
  ) => void;
  onRemoveAccess: (sectionIndex: number, accessIndex: number) => void;
  onUpdateAccessView: (
    sectionIndex: number,
    updatedAccessView: AccessView
  ) => void;
  onRemoveCard: (sectionName: Sections) => void;
  cities: ListTypeRes;
  accountNames: { id: number; name: string }[];
}

const SectionComponent: React.FC<SectionComponentProps> = ({
  sectionIndex,
  sectionData,
  onAddAccess,
  onUpdateAccess,
  onRemoveAccess,
  onUpdateAccessView,
  onRemoveCard,
  cities,
  accountNames,
}) => {
  const [accessType, setAccessType] = useState<AccessType>(sectionData[1][0]);
  const [numberArray, setNumberArray] = useState<number[] | null>(
    sectionData[1][1]
  );

  const handleAccessTypeChange = (value: AccessType) => {
    setAccessType(value);
    setNumberArray(null); // Reset numberArray to null
    onUpdateAccessView(sectionIndex, [value, null]);
  };

  const handleNumberArrayChange = (value: number[]) => {
    setNumberArray(value.length ? value : null);
    onUpdateAccessView(sectionIndex, [accessType, value.length ? value : null]);
  };

  return (
    <Card
      title={sectionNames[sectionData[0]] || sectionData[0]}
      extra={
        <div className="flex flex-row gap-3 items-center">
          <Button onClick={() => onAddAccess(sectionIndex)}>
            <AiOutlinePlus size={16} /> Access
          </Button>
          <Button danger onClick={() => onRemoveCard(sectionData[0])}>
            <PiTrashSimple size={16} />
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col
            span={
              accessType === "city-group" || accessType === "access-group"
                ? 12
                : 24
            }
          >
            <Form.Item label="Access Type">
              <Select value={accessType} onChange={handleAccessTypeChange}>
                {accessTypeOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {(accessType === "city-group" || accessType === "access-group") && (
            <Col span={12}>
              <Form.Item
                label={accessType === "city-group" ? "Cities" : "Users"}
              >
                <Select
                  showSearch
                  options={
                    (accessType === "city-group" ? cities : accountNames)?.map(
                      (c) => ({
                        label: c.name,
                        value: c.id,
                      })
                    ) || []
                  }
                  filterOption={filterOption}
                  onChange={handleNumberArrayChange}
                  mode="multiple"
                  value={numberArray || []}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {sectionData[2].map((access, accessIndex) => (
        <AccessComponent
          key={accessIndex}
          sectionIndex={sectionIndex}
          accessIndex={accessIndex}
          accessData={access}
          onUpdateAccess={onUpdateAccess}
          onRemoveAccess={onRemoveAccess}
          cities={cities}
          accountNames={accountNames}
        />
      ))}
    </Card>
  );
};

export default SectionComponent;
