import { useState } from "react";
import { Button, TableProps } from "antd";
import { FaEye } from "react-icons/fa";
import { useMomentTZ } from "../../../hooks/useMomentTZ";
import { Log } from "../../../models/interfaces";
import MoreErrorInfoModal from "../../../components/SuperPanel/ErrorLogs/MoreErrorInfoModal";

const useLogsColumns = () => {
  const { formatDateTimeWithTimezone } = useMomentTZ();
  const [showMoreErrorInfoModal, setShowMoreErrorInfoModal] = useState({
    show: false,
    payload: "",
  });

  const columns: TableProps<Log>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "section",
      title: "Section",
      dataIndex: "section",
    },
    {
      key: "detail",
      title: "Detail",
      dataIndex: "detail",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
    },
    {
      key: "accountName",
      title: "Account Name",
      dataIndex: "accountName",
    },
    {
      key: "linkId",
      title: "Link ID",
      dataIndex: "linkId",
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      render: (column) => formatDateTimeWithTimezone(column),
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => (
        <div className="flex flex-row items-center justify-end gap-1">
          <Button
            className="flex items-center px-3 py-2"
            onClick={() => {
              setShowMoreErrorInfoModal({
                show: true,
                payload: row.data
                  ? typeof row.data === "string"
                    ? row.data
                    : JSON.stringify(row.data)
                  : "Empty",
              });
            }}
          >
            <FaEye size={14} />
          </Button>
        </div>
      ),
    },
  ];

  const relatedComponents = [
    <MoreErrorInfoModal
      key="moreErrorInfoModal"
      show={showMoreErrorInfoModal.show}
      payload={showMoreErrorInfoModal.payload}
      stack={""}
      onHide={() => setShowMoreErrorInfoModal({ show: false, payload: "" })}
    />,
  ];

  return {
    columns,
    components: relatedComponents,
  };
};

export default useLogsColumns;
