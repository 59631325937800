import { create } from "zustand";

type GlobalLoadingStore = {
  isGlobalLoading: boolean;
  setGlobalLoading: (val: boolean) => void;
};

const useGlobalLoading = create<GlobalLoadingStore>((set) => ({
  isGlobalLoading: false,
  setGlobalLoading: (isGlobalLoading) => set({ isGlobalLoading }),
}));

export default useGlobalLoading;
