import { create } from "zustand";
import { UserAuth, UserSettings } from "../models/interfaces";

type UserStore = {
  user: UserAuth | null;
  userSettings?: UserSettings;
  isAuth: boolean;
  setUser: (user: UserAuth | null) => void;
  setIsAuth: (isAuth: boolean) => void;
  setUserSettings: (settings: UserSettings) => void;
};

const useUser = create<UserStore>((set) => ({
  user: null,
  isAuth: false,
  userSettings: undefined,
  setUser: (user) => set({ user }),
  setIsAuth: (isAuth) => set({ isAuth }),
  setUserSettings: (settings) => set({ userSettings: settings }),
}));

export default useUser;
