import { FinanceRecordStatus } from "../models/interfaces";
import { RiderStatus, RiderWaitingStatus } from "../models/rider";

export const filterOption = (
  input: string,
  option?: {
    label: string;
    value: string | number;
    idValueSearch?: string | number;
  }
) => {
  const parsedInput = Number(input);
  if (!isNaN(parsedInput) && option?.idValueSearch) {
    return (
      option.idValueSearch.toString().startsWith(parsedInput.toString()) ||
      false
    );
  } else {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  }
};

export const getWaitingListBadgeColor = (
  status: RiderWaitingStatus | "pending"
): string => {
  switch (status) {
    case "accepted":
      return "#36d399";
    case "waiting":
      return "#fbbd23";
    case "pending":
      return "#fbbd23";
    case "review":
      return "#fbbd23";
    case "ex-rider":
      return "#9CA3AF";
    case "rejected":
      return "#e11d48";
    case "interview":
      return "#3b82f6";
    case "training":
      return "#10b981";
    case "warehouse":
      return "#c4b5fd";
    case "onboard":
      return "#f59e0b";
    case "audit":
      return "#4b5563";
    default:
      return "";
  }
};

export const getFinanceRecordBadgeColor = (
  status: FinanceRecordStatus
): string => {
  switch (status) {
    case "completed":
      return "#36d399";
    case "pending":
      return "#fbbd23";
    case "under-review":
      return "#9CA3AF";
    case "cancelled":
      return "#e11d48";
    case "refunded":
      return "#3b82f6";
    default:
      return "";
  }
};
export const getColorForScoreTen = (score: number) => {
  if (score > 6) {
    return "green";
  } else if (score > 3) {
    return "orange";
  } else {
    return "red";
  }
};

export const getRiderBadgeType = (status: RiderStatus): string => {
  switch (status) {
    case "working":
      return "#36d399";
    case "on leave":
      return "#fbbd23";
    case "resign":
      return "#e8e8e8";
    case "terminated":
      return "#e11d48";
    case "frozen":
      return "#3b82f6";
    case "suspended":
      return "#e11d48";
    case "ready":
      return "#28a745";
    default:
      return "";
  }
};
