import { useEffect } from "react";
import { Button, Col, Form, FormProps, InputNumber, Row, Select } from "antd";
import { toast } from "react-toastify";
import useAxios from "../../../../hooks/useAxios";
import { PerformanceGeneralSettings } from "../../../../models/performance";

interface GeneralSettingsFormProps {
  refetch: () => void;
  data?: PerformanceGeneralSettings;
}

const PerformanceGeneralSettingsForm = ({
  refetch,
  data,
}: GeneralSettingsFormProps) => {
  const [form] = Form.useForm<PerformanceGeneralSettings>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onFinish: FormProps<PerformanceGeneralSettings>["onFinish"] = async (
    values
  ) => {
    // if (values.leftBlocksEffect + values.rightBlocksEffect !== 1) {
    //   toast.error("Left and Right Blocks Effect should be equal to 100%");
    //   return;
    // }

    // if (values.universalRank.reduce((a, b) => a + b, 0) !== 1) {
    //   toast.error("Sum of Universal Rank should be equal to 100%");
    //   return;
    // }

    // if (values.percentageRank.reduce((a, b) => a + b, 0) !== 1) {
    //   toast.error("Sum of Percentage Rank should be equal to 100%");
    //   return;
    // }

    await fetch({
      url: "/admin/riders/performance/settings/general",
      method: "PUT",
      payload: values,
      onSuccess: () => {
        toast.success("General settings updated successfully");
        refetch();
      },
    });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Cluster Size"
            name="clusterSize"
            rules={[{ required: true, type: "number", min: 2, max: 10 }]}
          >
            <InputNumber min={2} max={10} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Left Blocks Effect"
            name="leftBlocksEffect"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Right Blocks Effect"
            name="rightBlocksEffect"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Universal Rank"
            name="universalRank"
            rules={[
              {
                required: true,
                type: "array",
                min: 2,
                max: 10,
                // validator: (_, value) =>
                //   value &&
                //   value.reduce((a: number, b: number) => a + b, 0) === 1
                //     ? Promise.resolve()
                //     : Promise.reject(
                //         new Error("Sum of Universal Rank should be 100%")
                //       ),
              },
            ]}
          >
            <Select mode="tags" tokenSeparators={[","]} className="w-full">
              {Array.from({ length: 9 }, (_, i) => i + 1).map((value) => (
                <Select.Option key={value} value={value / 10}>
                  {value / 10}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Percentage Rank"
            name="percentageRank"
            rules={[
              {
                required: true,
                type: "array",
                min: 2,
                max: 10,
                validator: (_, value) =>
                  value &&
                  value.reduce((a: number, b: number) => a + b, 0) === 1
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Sum of Percentage Rank should be 100%")
                      ),
              },
            ]}
          >
            <Select mode="tags" tokenSeparators={[","]} className="w-full">
              {Array.from({ length: 9 }, (_, i) => i + 1).map((value) => (
                <Select.Option key={value} value={value / 10}>
                  {value / 10}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end w-full">
        <Button
          htmlType="submit"
          type="primary"
          className="bg-primary"
          loading={isLoading}
        >
          Save Settings
        </Button>
      </div>
    </Form>
  );
};

export default PerformanceGeneralSettingsForm;
