import { Button, Modal } from "antd";
import { BsPersonFillLock } from "react-icons/bs";
import { IoIosLock } from "react-icons/io";
import useAxios from "../../../../hooks/useAxios";
import useFetch from "../../../../hooks/useFetch";
import Loading from "../../../common/Loading";
import { VscVmActive } from "react-icons/vsc";

const SystemMode = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { fetch, isLoading: isSubmitLoading } = useAxios({
    disabledStates: false,
  });
  const { isLoading, data, refetch } = useFetch<{ status: string }>({
    url: "/admin/system/status",
    method: "GET",
  });

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="flex flex-row gap-4 items-center">
        <Button
          danger
          onClick={() => {
            modal.confirm({
              title: "Confirm",
              content: "Are you sure to lock the system for everyone",
              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                fetch({
                  url: "/admin/system/status/lock-down",
                  method: "POST",
                  payload: {},
                  onSuccess: () => {
                    refetch();
                  },
                });
              },
              centered: true,
            });
          }}
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2"
          loading={isSubmitLoading}
        >
          <IoIosLock size={20} /> Lock
        </Button>
        {data?.status === "active" && (
          <Button
            danger
            onClick={() => {
              modal.confirm({
                title: "Confirm",
                content: "Are you sure to limit access the system",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {
                  fetch({
                    url: "/admin/system/status/limited-access",
                    method: "POST",
                    payload: {},
                    onSuccess: () => {
                      refetch();
                    },
                  });
                },
                centered: true,
              });
            }}
            className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2"
            loading={isSubmitLoading}
          >
            <BsPersonFillLock size={20} /> Limited Access
          </Button>
        )}
        {data?.status !== "active" && (
          <Button
            onClick={() => {
              modal.confirm({
                title: "Confirm",
                content: "Are you sure to Re-Activate the system",
                okText: "Yes",
                cancelText: "No",
                onOk: () => {
                  fetch({
                    url: "/admin/system/status/active",
                    method: "POST",
                    payload: {},
                    onSuccess: () => {
                      refetch();
                    },
                  });
                },
                centered: true,
              });
            }}
            className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2"
            loading={isSubmitLoading}
          >
            <VscVmActive size={20} /> Re-Activate
          </Button>
        )}
      </div>
      {contextHolder}
    </div>
  );
};

export default SystemMode;
