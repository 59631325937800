import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Descriptions, Row, Skeleton } from "antd";
import { RiderDataCard } from "../../models/rider";
import moment from "moment-timezone";
import useAxios from "../../hooks/useAxios";
import { uniqueId } from "../../utils/IDUtils";
import { GoPerson } from "react-icons/go";
import { useTranslation } from "react-i18next";

interface RiderCardProps {
  show: boolean;
  id?: number;
  id_type?: "id" | "partner_id";
}

const RiderCard = ({ id, show, id_type = "id" }: RiderCardProps) => {
  const { t } = useTranslation();
  const [riderData, setRiderData] = useState<RiderDataCard>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  useEffect(() => {
    if (show && id) {
      fetch<RiderDataCard>({
        url: `/admin/riders/${id}`,
        method: "GET",
        config: {
          headers: {
            id_type,
          },
        },
        onSuccess: (res) => {
          setRiderData(res.data);
        },
      });
    }

    if (!show || !id) {
      setRiderData(undefined);
    }
  }, [show, id]);

  if (isLoading) {
    return (
      <Card bordered={false} className="mb-5">
        <Row gutter={16}>
          <Col span={10}>
            <Skeleton.Image />
          </Col>
          <Col span={14}>
            <Skeleton />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <>
      {riderData && (
        <Card bordered={false} className="mb-5">
          <Row gutter={16}>
            <Col span={10}>
              <Avatar
                shape="square"
                className="w-full h-full max-w-full max-h-[180px] object-fill cstm-avatar"
                src={
                  import.meta.env.VITE_API_URL +
                  "/" +
                  riderData.account.imageURL +
                  `?uniqueid=${uniqueId()}`
                }
                alt={riderData.account.fullName}
                icon={<GoPerson size={42} />}
                crossOrigin="use-credentials"
              />
            </Col>
            <Col span={14}>
              <Descriptions
                column={1}
                className="description-cstm-0-p flex flex-col"
              >
                <Descriptions.Item label={t("full name")} className="p-0">
                  {riderData.account.fullName}
                </Descriptions.Item>
                <Descriptions.Item label={t("phone number")}>
                  {riderData.account.phoneNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t("city")}>
                  {riderData.account.city}
                </Descriptions.Item>
                <Descriptions.Item label={t("partner id")}>
                  {riderData.rider.partnerID}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col span={24}>
              <Descriptions column={2}>
                <Descriptions.Item label={t("status")}>
                  {riderData.rider.status}
                </Descriptions.Item>
                <Descriptions.Item label={t("plate number")}>
                  {riderData.rider.plateNumber}
                </Descriptions.Item>
                <Descriptions.Item label={t("contract end date")}>
                  {moment(riderData.rider.contractEndDate).format("DD-MM-YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label={t("vehicle type")}>
                  {riderData.vehicle.name}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default React.memo(RiderCard);
