import { useEffect } from "react";
import useLists, { ListsReqConfig } from "../store/Lists";

interface ListsReqProps {
  requestLists: ListsReqConfig[];
}

const useListsReq = ({ requestLists }: ListsReqProps) => {
  const { getLists, ...rest } = useLists();

  useEffect(() => {
    getLists(requestLists);
  }, []);

  return rest;
};

export default useListsReq;
