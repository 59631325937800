import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import * as Sentry from "@sentry/react";

interface ErrorBoundaryProps {
  fallback: ReactNode;
  children: ReactNode;
  navigate: (path: string) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundaryClass extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      // Navigate to home page and force a reload
      this.props.navigate("/");
      window.location.reload(); // This forces the page to reload after navigating
    } else {
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  return (
    <ErrorBoundaryClass fallback={<ErrorPage />} navigate={navigate}>
      {children}
    </ErrorBoundaryClass>
  );
};

export default ErrorBoundary;
