import CountDown from "../common/CountDown";
import { ModalFunc } from "antd/es/modal/confirm";

export const showRateLimitModal = (
  timeoutDuration: number,
  WarnModal: ModalFunc,
  onDone?: () => void
) => {
  WarnModal({
    title: "Rate Limit Exceeded",
    content: (
      <div>
        <div className="py-5">
          <CountDown
            duration={timeoutDuration}
            onComplete={() => {
              onDone && onDone();
              window.location.reload();
            }}
          />
        </div>

        <p>
          You have reached the maximum number of requests allowed. Please wait
          for the timer to expire before trying again.
        </p>
      </div>
    ),
    footer: null,
    closable: false,
    maskClosable: false,
    centered: true,
  });
};
