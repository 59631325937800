import { useEffect } from "react";
import { Button, Col, Form, InputNumber, Row, FormProps } from "antd";
import { toast } from "react-toastify";
import useAxios from "../../../../hooks/useAxios";
import moment from "moment-timezone";
import { PerformanceCalculationSettingForm } from "../../../../models/performance";
import DatePicker from "../../../UI/DatePicker";

interface CalculationPreferencesFormProps {
  refetch: () => void;
  data?: PerformanceCalculationSettingForm;
}

const PerformanceCalculationSettingsForm = ({
  refetch,
  data,
}: CalculationPreferencesFormProps) => {
  const [form] = Form.useForm<PerformanceCalculationSettingForm>();
  const { fetch, isLoading } = useAxios({ disabledStates: true });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        startDate: data.startDate && moment(data.startDate),
        endDate: data.endDate && moment(data.endDate),
      });
    }
  }, [data, form]);

  const onFinish: FormProps<PerformanceCalculationSettingForm>["onFinish"] =
    async (values) => {
      await fetch({
        url: "/admin/riders/performance/settings/calculation-preferences",
        method: "PUT",
        payload: {
          ...values,
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          endDate: moment(values.endDate).format("YYYY-MM-DD"),
        },
        onSuccess: () => {
          toast.success("Calculation preference updated successfully");
          refetch();
        },
      });
    };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[{ required: true }]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="End Date"
            name="endDate"
            // rules={[{ required: true }]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Daily Score Weight"
            name="dailyScoreWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Block Score Weight"
            name="blockScoreWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Daily Fine Tune Factor"
            name="dailyFineTuneFactor"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Block Fine Tune Factor"
            name="blockFineTuneFactor"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Working Hours Max"
            name="workingHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Working Hours Mid"
            name="workingHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Working Hours Min"
            name="workingHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Working Hours Weight"
            name="workingHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Acceptance Rate Max"
            name="acceptanceRateMax"
            rules={[{ required: true, type: "number", min: 0.5, max: 1 }]}
          >
            <InputNumber min={0.5} max={1} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Acceptance Rate Mid"
            name="acceptanceRateMid"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.99 }]}
          >
            <InputNumber min={0.1} max={0.99} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Acceptance Rate Min"
            name="acceptanceRateMin"
            rules={[{ required: true, type: "number", min: 0, max: 0.99 }]}
          >
            <InputNumber min={0} max={0.99} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Acceptance Rate Weight"
            name="acceptanceRateWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Late Shifts Max"
            name="lateShiftsMax"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Late Shifts Mid"
            name="lateShiftsMid"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Late Shifts Min"
            name="lateShiftsMin"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Late Shifts Weight"
            name="lateShiftsWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Absent Shifts Max"
            name="absentShiftsMax"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Absent Shifts Mid"
            name="absentShiftsMid"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Absent Shifts Min"
            name="absentShiftsMin"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Absent Shifts Weight"
            name="absentShiftsWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Break Hours Max"
            name="breakHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Break Hours Mid"
            name="breakHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Break Hours Min"
            name="breakHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Break Hours Weight"
            name="breakHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Working Diff Hours Max"
            name="workingDiffHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Working Diff Hours Mid"
            name="workingDiffHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Working Diff Hours Min"
            name="workingDiffHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Working Diff Hours Weight"
            name="workingDiffHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end w-full">
        <Button
          htmlType="submit"
          type="primary"
          className="bg-primary"
          loading={isLoading}
        >
          Save Settings
        </Button>
      </div>
    </Form>
  );
};

export default PerformanceCalculationSettingsForm;
