import { Progress } from "antd";
import { useEffect, useState } from "react";

interface CountdownProps {
  duration: number;
  onComplete?: () => void;
}

const CountDown = ({ duration, onComplete }: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (onComplete) onComplete();
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft, onComplete]);

  const formatTime = (seconds: number) => {
    const remainingSeconds = seconds % 60;
    return remainingSeconds;
  };

  return (
    <div className="text-center text-lg font-medium">
      <Progress
        type="circle"
        format={() => timeLeft}
        percent={(formatTime(timeLeft) / duration) * 100}
        status="success"
        strokeColor={{
          "20%": "#36d399",
          "40%": "#fbbd23",
          "100%": "#e11d48",
        }}
      />
    </div>
  );
};

export default CountDown;
