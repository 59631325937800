import { Drawer as DrawerComp } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface DrawerCompProps {
  title: string;
  onClose: () => void;
  open: boolean;
  width?: string;
  children: ReactNode;
}

const Drawer = ({
  title,
  onClose,
  open,
  width = "800px",
  children,
}: DrawerCompProps) => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  return (
    <DrawerComp
      title={title}
      placement={dir === "ltr" ? "right" : "left"}
      onClose={onClose}
      destroyOnClose={true}
      open={open}
      width={width}
      styles={{ body: { paddingBottom: "80px" } }}
    >
      {children}
    </DrawerComp>
  );
};

export default Drawer;
