import { Spin } from "antd";
import useTheme from "../../store/Theme";

interface LoadingProps {
  cWidth?: string;
  cHeight?: string;
  lSize?: "small" | "default" | "large";
}

const Loading = ({
  cWidth = "w-full",
  cHeight = "h-96",
  lSize = "large",
}: LoadingProps) => {
  const { theme } = useTheme();

  return (
    <div
      className={`flex flex-row justify-center items-center ${cWidth} ${cHeight} ${
        theme === "dark" ? "bg-black" : ""
      }`}
    >
      <Spin className="text-center" size={lSize}>
        <div className="content" />
      </Spin>
    </div>
  );
};

export default Loading;
